import {t} from '@lingui/macro';
import React, {useRef, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Styles, View, ScrollView, Text, Link, Button, Image, Modal, Types} from 'react-ult';
import WebView, {Types as WebTypes} from 'react-ult-ext-webview';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {useMenu} from 'hooks/useMenu';
import {useMenuSort} from 'hooks/useMenuSort';
import {useMenuFilter} from 'hooks/useMenuFilter';
import {useModalUpsellStorage} from 'hooks/useModalUpsellStorage';
import {SafeAreaView} from 'controls/SafeArea';
import {IconButton} from 'controls/IconButton';
import {AppNotifications, modalAppSort} from 'view/AppNotifications';
import {Light, Colors, Breakpoints, Vectors} from 'features/themes';
import {getHost, isIOS, isNative, isSafari, isTouch} from 'features/platform';
import {destroySession, openPage, featuresList} from 'features/mediafire';
import {bytesize, ekeyToPercentile} from 'features/common';
import images from 'features/images';
import device from 'features/device';
import {AppFeatures} from 'globals/types';
import {VERSION} from 'globals/config';
import * as files from 'store/files';
import * as app from 'store/app';

import * as appEffects from 'store/app/effects';
import * as filesEffects from 'store/files/effects';

export function AppMenu() {
  const host = getHost();
  const hasTouch = isTouch();
  const isPromoDisabled = isIOS() || isSafari();
  const anchorMenuSort = useRef(null);
  const anchorMenuFilter = useRef(null);
  const anchorMenuPolicies = useRef(null);
  const name = useSelector(app.selectors.getName);
  const ekey = useSelector(app.selectors.getEkey);
  const email = useSelector(app.selectors.getEmail);
  const limits = useSelector(app.selectors.getLimits);
  const viewport = useSelector(app.selectors.getViewport);
  const userAvatar = useSelector(app.selectors.getAvatar);
  const earlyAccess = useSelector(app.selectors.getFeature);
  const notifications = useSelector(app.selectors.getNotifications);
  const isBusiness = useSelector(app.selectors.isBusiness);
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const isPremium = useSelector(app.selectors.isPremium);
  const isTrial = useSelector(app.selectors.isTrial);
  const sort = useSelector(files.selectors.getSort);
  const filter = useSelector(files.selectors.getFilter);
  const layout = useSelector(files.selectors.getLayout);
  const current = useSelector(files.selectors.getCurrent);
  const trash = useSelector(files.selectors.getItem('trash'));
  const trashView = useSelector(files.selectors.getView('trash'));
  const isAvatarDefault = userAvatar.indexOf('/myfiles/default.png') !== -1;
  const isFollowing = current === 'following' && !earlyAccess;
  const isRecent = current === 'recent' && !earlyAccess;
  const isTrash = current === 'trash' && !earlyAccess;
  const isFiles = !isTrash && !isRecent && !isFollowing && !earlyAccess;
  const isMenuFloating = viewport.width <= Breakpoints.menu;
  const isNotificationsMaximized = viewport.width <= 440;
  const storageTotal = limits ? limits.storage.total : 0;
  const storagedUsed = limits ? limits.storage.used : 0;
  const storagePixels = Math.max(0, Math.min(168, (storagedUsed / storageTotal) * 168));
  const storagePercent = Math.max(0, Math.min(100, (storagedUsed / storageTotal) * 100));
  const styleHeight = Styles.createScrollViewStyle({minHeight: viewport.height}, false);
  const storageWidth = Styles.createViewStyle({width: isNaN(storagePixels) ? 0 : storagePixels}, false);
  const storageWarn = Styles.createViewStyle({backgroundColor: Colors.secondary.brightRed}, false);
  const imageAvatar = (isAvatarDefault || !userAvatar) ? images.avatar : userAvatar;
  const textTrashCount = trash && trashView ? trash.files + trash.folders : 0;
  const textStorageUsed = `${bytesize(storagedUsed)} / ${bytesize(storageTotal, 0)}`;
  const hasNotifications = notifications > 0;
  const hasSharedWithMe = !isTrial;
  const hasNewFeatures = false && device.isPreferredGeo() && ekeyToPercentile(ekey) <= 50;
  const viewIcon = layout === 'list' ? Vectors.iconGrid : Vectors.iconList;
  const viewLabel = layout === 'list' ? t`GRID VIEW` : t`LIST VIEW`;
  const sortLabel = t`SORT ${sort.category.toUpperCase()}`;
  const filterLabel = t`SHOW ${filter.type.toUpperCase()}`;
  const classes = {
    root: [
      styles.root,
      isMenuFloating && styles.rootFloating,
    ],
    menu: [
      styles.menu,
    ],
    bottomMenu: [
      styles.bottomMenu,
      !isNative() && styles.bottomMenuWeb,
    ],
    notification: [
      styles.notification,
      !isNative() && styles.notificationWeb,
    ],
    bar: [
      styles.used,
      storageWidth,
      storagePercent > 80 && storageWarn,
    ],
    button: [
      styles.button,
      hasTouch && styles.buttonTouch,
    ]
  };

  const dispatch = useDispatch();
  const logout = useCallback(() => destroySession(dispatch), []);
  const showEarlyAccess = useCallback(appEffects.earlyAccess(dispatch), []);
  const gotoAccount = useCallback(appEffects.gotoAccount(), []);
  const gotoSupport = useCallback(appEffects.gotoSupport(), []);
  const gotoAvatar = useCallback(appEffects.gotoAccountAvatar(), []);
  const gotoEarnSpace = useCallback(appEffects.gotoEarnSpace(), []);
  const gotoUpgradeTop = useCallback(appEffects.gotoUpgrade('utm_source=sidebar&utm_medium=website&utm_content=upgrade_pro&utm_campaign=upgrade_v2_20210816&mf_source=sidebar&mf_medium=website&mf_content=upgrade_pro&mf_campaign=upgrade_v2_20210816'), []);
  const gotoUpgradeBot = useCallback(appEffects.gotoUpgrade('utm_source=sidebar&utm_medium=website&utm_content=upgrade&utm_campaign=upgrade_v1_20210816&mf_source=sidebar&mf_medium=website&mf_content=upgrade&mf_campaign=upgrade_v1_20210816'), []);
  const gotoFollowing = useCallback(filesEffects.navigate(dispatch, 'following'), []);
  const gotoMyfiles = useCallback(filesEffects.navigate(dispatch, 'myfiles'), []);
  const gotoRecent = useCallback(filesEffects.navigate(dispatch, 'recent'), []);
  const gotoTrash = useCallback(filesEffects.navigate(dispatch, 'trash'), []);
  const toggleView = useCallback(filesEffects.layout(dispatch), []);
  const dragOver = useCallback(filesEffects.dragOver(), []);
  const dropTrash = useCallback(filesEffects.dragDropTrash(dispatch), []);

  const [showStorageReminder] = useModalUpsellStorage('remind');
  const [showMenuFilter] = useMenuFilter(current, sort, filter, filesEffects.filter(dispatch, current), anchorMenuFilter.current);
  const [showMenuSort] = useMenuSort(current, sort, filter, filesEffects.sort(dispatch, current), anchorMenuSort.current);
  const [showMenuMore, closeMenuMore] = useMenu([
    {
      command: 'about',
      text: t`ABOUT`,
    }, {
      command: 'policies',
      text: t`POLICIES`,
    }, {
      command: 'privacy',
      text: t`PRIVACY`,
    }, {
      command: 'abuse',
      text: t`ABUSE`,
    }, {
      command: 'help',
      text: t`HELP`,
    },
  ], (command) => {
    switch (command) {
      case 'about':
        openPage(`${getHost()}/about/`);
        break;
      case 'policies':
        openPage(`${getHost()}/policies/terms_of_service.php`);
        break;
      case 'privacy':
        openPage(`${getHost()}/policies/privacy_policy.php`);
        break;
      case 'abuse':
        openPage(`${getHost()}/policy_violation/terms_of_service.php`);
        break;
      case 'help':
        openPage(`${getHost()}/help/submit_a_ticket.php`);
        break;
    }
    closeMenuMore();
  }, anchorMenuPolicies.current, undefined, ['bottom', 'top'], true);

  const openNotifications = useCallback(() => Modal.show(
    <AppNotifications floatRight={isMenuFloating} maximized={isNotificationsMaximized}/>,
    modalAppSort), [isMenuFloating, isNotificationsMaximized]);

  const menuItemEarlyAccess = useCallback((feature: AppFeatures, i: number) => {
    const isActive = (feature === earlyAccess) || (earlyAccess === 'Overview' && i === 0);
    return (
      <View key={i} accessibilityTraits={Types.AccessibilityTrait.MenuItem} style={styles.item}>
        <IconButton
          text={feature.toUpperCase()}
          title={t`Get early access to ${feature}`}
          onPress={showEarlyAccess(earlyAccess ? feature : 'Overview', i)}
          textStyle={[styles.text, isActive && styles.active]}
          buttonStyle={classes.button}
          buttonHoverStyle={styles.buttonHover}
          icon={
            <Svg viewBox="0 0 24 24" width={24} height={24}>
              {Vectors[`feat${feature}`].map((path: string, i: number) =>
                <SvgPath key={i} d={path} fillColor={isActive ? Colors.neutral.white : Colors.neutral.mid}/>
              )}
            </Svg>
          }
        />
      </View>
    );
  }, [earlyAccess]);
  
  useEffect(() => {
    if (storagePercent >= 95) {
      showStorageReminder();
    }
  }, []);

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: Colors.neutral.dark}}>
      <View style={classes.root} accessibilityLabel="Main Menu">
        <ScrollView style={classes.menu} bounces={false} overScrollMode="never">
          <View style={[styles.mainMenu, styleHeight]}>
            <View style={styles.topMenu}>
              {isLoggedIn &&
                <View style={styles.user} accessibilityTraits={Types.AccessibilityTrait.MenuBar}>
                  {email && imageAvatar &&
                    <View onPress={gotoAvatar}>
                      <Image style={styles.avatar} resizeMode="cover" source={imageAvatar}/>
                    </View>
                  }
                  <View
                    accessibilityTraits={Types.AccessibilityTrait.Menu}
                    style={[styles.userDetails, !email && styles.userDetailsTrial]} title={`${name}\n${email}`}>
                    <View
                      style={styles.header}>
                      <Text
                        style={[styles.name, !email && styles.nameTrial]}
                        numberOfLines={1}>
                        {email ? name : t`Trial Account`}
                      </Text>
                    </View>
                    {email &&
                      <Text
                        style={styles.email}
                        numberOfLines={1}>
                        {email}
                      </Text>
                    }
                    {!email &&
                      <Text style={styles.trial}>
                        {t`This account is not registered to an email address. Unregistered accounts may be considered abandoned after 14 days of inactivity, unless you register.`}
                        <Link style={styles.trialLink} url={`${host}/upgrade/`}>
                          {t`Register now.`}
                        </Link>
                      </Text>
                    }
                    {<Button
                      accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                      style={classes.notification}
                      title={t`Open notifications`}
                      onPress={openNotifications}>
                      <Svg viewBox="0 0 24 24" width={16} height={20}>
                        <SvgPath
                          d={hasNotifications ? Vectors.iconAlertNew : Vectors.iconAlert}
                          fillColor={hasNotifications ? Colors.secondary.brightRed : Colors.neutral.mid}
                        />
                      </Svg>
                    </Button>}
                  </View>
                </View>
              }
              <View style={styles.divider}></View>
              {!isPromoDisabled && !isPremium && email &&
                <View
                  accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                  style={styles.item}>
                  <IconButton
                    text={t`UPGRADE TO PRO`}
                    title={t`Upgrade your account`}
                    onPress={gotoUpgradeTop}
                    textStyle={styles.upsell}
                    buttonStyle={[classes.button, styles.upsell]}
                    buttonHoverStyle={styles.buttonHover}
                    icon={
                      <Svg viewBox="0 0 20 20" width={20} height={20}>
                        <SvgPath d={Vectors.iconPro[0]} fillColor={Colors.secondary.green}/>
                        <SvgPath d={Vectors.iconPro[1]} fillColor={Colors.neutral.white}/>
                      </Svg>
                    }
                  />
                </View>
              }
              <View
                accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                style={[styles.item, isFiles && styles.active]}>
                <IconButton
                  text={t`MY FILES`}
                  title={t`Go to My Files`}
                  onPress={gotoMyfiles}
                  textStyle={[styles.text, isFiles && styles.active]}
                  buttonStyle={classes.button}
                  buttonHoverStyle={styles.buttonHover}
                  icon={
                    <Svg viewBox="0 0 20 20" width={20} height={20}>
                      <SvgPath d={Vectors.iconFolder} fillColor={isFiles ? Colors.neutral.white : Colors.neutral.mid}/>
                    </Svg>
                  }
                />
              </View>
              <View
                accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                style={[styles.item, isRecent && styles.active]}>
                <IconButton
                  text={t`RECENT FILES`}
                  title={t`Go to Recent Files`}
                  onPress={gotoRecent}
                  textStyle={[styles.text, isRecent && styles.active]}
                  buttonStyle={classes.button}
                  buttonHoverStyle={styles.buttonHover}
                  icon={
                    <Svg viewBox="0 0 20 20" width={20} height={20}>
                      <SvgPath d={Vectors.iconRecent} fillColor={isRecent ? Colors.neutral.white : Colors.neutral.mid}/>
                    </Svg>
                  }
                />
              </View>
              {hasSharedWithMe &&
                <View
                  accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                  style={[styles.item, isFollowing && styles.active]}>
                  <IconButton
                    text={t`SHARED WITH ME`}
                    title={t`Go to Shared Files`}
                    onPress={gotoFollowing}
                    textStyle={[styles.text, isFollowing && styles.active]}
                    buttonStyle={classes.button}
                    buttonHoverStyle={styles.buttonHover}
                    icon={
                      <Svg viewBox="0 0 24 24" width={24} height={24}>
                        <SvgPath d={Vectors.iconFollowing} fillColor={isFollowing ? Colors.neutral.white : Colors.neutral.mid}/>
                      </Svg>
                    }
                  />
                </View>
              }
              <View
                accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                style={[styles.item, isTrash && styles.active]}
                onDragOver={dragOver}
                onDrop={dropTrash}>
                <IconButton
                  text={t`TRASH (${trash ? textTrashCount : 0})`}
                  title={t`Go to Trash`}
                  onPress={gotoTrash}
                  textStyle={[styles.text, isTrash && styles.active]}
                  buttonStyle={classes.button}
                  buttonHoverStyle={styles.buttonHover}
                  icon={
                    <Svg viewBox="0 0 14 20" width={20} height={20}>
                      <SvgPath d={Vectors.iconTrash} fillColor={isTrash ? Colors.neutral.white : Colors.neutral.mid}/>
                    </Svg>
                  }
                />
              </View>
              {isMenuFloating && <React.Fragment>
                <View style={styles.divider}></View>
                <View
                  accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                  style={[styles.item, isFollowing && styles.active]}>
                  <IconButton
                    ref={anchorMenuSort}
                    text={sortLabel}
                    title={t`Change sort`}
                    onPress={showMenuSort}
                    textStyle={styles.text}
                    buttonStyle={classes.button}
                    buttonHoverStyle={styles.buttonHover}
                    icon={
                      <Svg viewBox="0 0 20 20" width={20} height={20}>
                        <SvgPath d={Vectors.iconSortAlt} fillColor={Colors.neutral.mid}/>
                      </Svg>
                    }
                  />
                </View>
                <View
                  accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                  style={[styles.item, isFollowing && styles.active]}>
                  <IconButton
                    ref={anchorMenuFilter}
                    text={filterLabel}
                    title={t`Change filter`}
                    onPress={showMenuFilter}
                    textStyle={styles.text}
                    buttonStyle={classes.button}
                    buttonHoverStyle={styles.buttonHover}
                    icon={
                      <Svg viewBox="0 0 24 24" width={24} height={24}>
                        <SvgPath d={Vectors.iconFilter} fillColor={Colors.neutral.mid}/>
                      </Svg>
                    }
                  />
                </View>
                <View
                  accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                  style={[styles.item, isFollowing && styles.active]}>
                  <IconButton
                    text={viewLabel}
                    title={t`Change view`}
                    onPress={toggleView}
                    textStyle={styles.text}
                    buttonStyle={classes.button}
                    buttonHoverStyle={styles.buttonHover}
                    icon={
                      <Svg viewBox="0 0 24 24" width={24} height={24}>
                        <SvgPath d={viewIcon} fillColor={Colors.neutral.mid}/>
                      </Svg>
                    }
                  />
                </View>
              </React.Fragment>}
              {hasNewFeatures && (
                <React.Fragment>
                  <View style={[styles.divider, styles.dividerWithTag]}>
                    <Text style={styles.dividerTag}>
                      coming soon
                    </Text>
                  </View>
                  {featuresList.map(menuItemEarlyAccess)}
                </React.Fragment>
              )}
              <View style={styles.divider}></View>
              {!isPremium && !isIOS() &&
                <View
                  accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                  style={styles.item}>
                  <IconButton
                    text={t`EARN SPACE`}
                    title={t`Earn space`}
                    onPress={gotoEarnSpace}
                    textStyle={styles.text}
                    buttonStyle={classes.button}
                    buttonHoverStyle={styles.buttonHover}
                    icon={
                      <Svg viewBox="0 0 20 20" width={20} height={20}>
                        <SvgPath d={Vectors.iconPlus} fillColor={Colors.neutral.mid}/>
                      </Svg>
                    }
                  />
                </View>
              }
              {email &&
                <View
                  accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                  style={styles.item}>
                  <IconButton
                    text={t`ACCOUNT`}
                    title={t`Open account settings`}
                    onPress={gotoAccount}
                    textStyle={styles.text}
                    buttonStyle={classes.button}
                    buttonHoverStyle={styles.buttonHover}
                    icon={
                      <Svg viewBox="0 0 20 20" width={20} height={20}>
                        <SvgPath d={Vectors.iconGear} fillColor={Colors.neutral.mid}/>
                      </Svg>
                    }
                  />
                </View>
              }
              <View
                accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                style={styles.item}>
                <IconButton
                  text={t`LOGOUT`}
                  title={t`Logout`}
                  onPress={logout}
                  textStyle={styles.text}
                  buttonStyle={classes.button}
                  buttonHoverStyle={styles.buttonHover}
                  icon={
                    <Svg viewBox="0 0 20 20" width={20} height={20}>
                      <SvgPath d={Vectors.iconExit} fillColor={Colors.neutral.mid}/>
                    </Svg>
                  }
                />
              </View>
              <View
                accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                style={styles.item}>
                <IconButton
                  text={t`HELP`}
                  title={t`Open support ticket page`}
                  onPress={gotoSupport}
                  textStyle={styles.text}
                  buttonStyle={classes.button}
                  buttonHoverStyle={styles.buttonHover}
                  icon={
                    <Svg viewBox="0 0 20 20" width={20} height={20}>
                      <SvgPath d={Vectors.iconHelp} fillColor={Colors.neutral.mid}/>
                    </Svg>
                  }
                />
              </View>
              <View
                accessibilityTraits={Types.AccessibilityTrait.MenuItem}
                style={styles.item}>
                <IconButton
                  ref={anchorMenuPolicies}
                  text={t`MORE`}
                  title={t`More links`}
                  onPress={showMenuMore}
                  textStyle={styles.text}
                  buttonStyle={classes.button}
                  buttonHoverStyle={styles.buttonHover}
                  iconStyle={styles.iconMore}
                  icon={
                    <Svg viewBox="0 0 20 20" width={20} height={20}>
                      <SvgPath d={Vectors.iconMoreHoriz} fillColor={Colors.neutral.mid}/>
                    </Svg>
                  }
                />
              </View>
              <View style={styles.spacer}></View>
            </View>
            {!isPromoDisabled && !isBusiness &&
              <View style={styles.promo}>
                <WebView
                  url={`${host}/widgets/sidebar_promo.php`}
                  sandbox={WebTypes.WebViewSandboxMode.AllowScripts | WebTypes.WebViewSandboxMode.AllowTopNavigation}
                  onLoad={console.debug}
                  onError={console.debug}
                />
              </View>
            }
            <View style={classes.bottomMenu}>
              <View style={styles.storageBar}>
                <View style={styles.bar}>
                  <View style={classes.bar}></View>
                </View>
              </View>
              <View style={styles.storageFooter}>
                <Text style={styles.details}>
                  {textStorageUsed}
                </Text>
                {isPremium || isPromoDisabled
                  ? <Text style={styles.upgrade} onPress={gotoAccount}>
                      {t`ACCOUNT`}
                    </Text>
                  : <Text style={styles.upgrade} onPress={gotoUpgradeBot}>
                      {email ? t`UPGRADE` : t`SIGN UP`}
                    </Text>
                }
              </View>
              <View style={styles.legal}>
                <View style={styles.version}>
                  <Text style={styles.copyright}>
                    © MediaFire
                  </Text>
                  <Text style={styles.build}>
                    {`v${process.env.ULT_APP_VERSION || VERSION}`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
}

export const styles = {
  root: Styles.createViewStyle({
    flex: 1,
    width: 200,
    backgroundColor: Colors.neutral.dark,
    borderColor: Light.mainMenuBorder,
    borderRightWidth: 1,
  }),
  rootFloating: Styles.createViewStyle({
    flex: 1,
    width: 200,
    backgroundColor: Colors.neutral.dark,
    borderLeftWidth: 1,
    borderRightWidth: 0,
  }),
  user: Styles.createViewStyle({
    flex: 0,
    flexDirection: 'row',
    marginTop: 16,
    marginBottom: 6,
    marginHorizontal: 16,
  }),
  userDetails: Styles.createViewStyle({
    flex: 1,
    marginLeft: 8,
  }),
  userDetailsTrial: Styles.createViewStyle({
    marginLeft: 0,
  }),
  header: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
  }),
  avatar: Styles.createImageStyle({
    borderRadius: 3,
    height: 36,
    width: 36,
  }),
  menu: Styles.createScrollViewStyle({
    flex: 1,
  }),
  mainMenu: Styles.createViewStyle({
    flex: 1,
  }),
  topMenu: Styles.createViewStyle({
    flex: 1,
  }),
  bottomMenu: Styles.createViewStyle({
    flex: 0,
    marginHorizontal: 16,
  }),
  bottomMenuWeb: Styles.createViewStyle({
    flex: 0,
    marginHorizontal: 16,
  }),
  item: Styles.createViewStyle({
    flex: 0,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 11,
    marginRight: 8,
  }),
  notification: Styles.createButtonStyle({
    position: 'absolute',
    cursor: 'pointer',
    right: -12,
    top: -6,
    padding: 6,
  }),
  notificationWeb: Styles.createButtonStyle({
    right: -8,
    top: -6,
  }),
  active: Styles.createTextStyle({
    color: Colors.neutral.white,
  }),
  name: Styles.createTextStyle({
    width: 100,
    fontSize: 13,
    color: Colors.neutral.white,
  }),
  nameTrial: Styles.createTextStyle({
    width: 100,
  }),
  email: Styles.createTextStyle({
    width: 128,
    fontSize: 11,
    marginBottom: 2,
    color: Colors.neutral.mid,
  }),
  trial: Styles.createTextStyle({
    fontSize: 11,
    color: Colors.secondary.yellow,
    marginVertical: 10,
  }),
  trialLink: Styles.createLinkStyle({
    fontSize: 12,
    marginLeft: 4,
    color: Colors.secondary.yellow,
    textDecorationColor: Colors.secondary.yellow,
    textDecorationLine: 'underline',
  }),
  button: Styles.createLinkStyle({
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 10,
    lineHeight: 36,
    height: 36,
    width: 178,
    borderRadius: 0,
    color: Colors.neutral.mid,
  }),
  buttonHover: Styles.createButtonStyle({
    backgroundColor: Colors.neutral.darker,
  }),
  buttonTouch: Styles.createLinkStyle({
    lineHeight: 40,
    height: 40,
  }),
  text: Styles.createTextStyle({
    color: Colors.neutral.mid,
    fontSize: 12,
  }),
  textSmall: Styles.createTextStyle({
    color: Colors.neutral.mid,
    fontSize: 9,
  }),
  upsell: Styles.createTextStyle({
    color: Colors.secondary.green,
    fontSize: 12,
  }),
  orange: Styles.createTextStyle({
    color: Colors.secondary.orange,
    fontSize: 12,
  }),
  details: Styles.createTextStyle({
    fontSize: 10,
    flex: 1,
    color: Colors.neutral.light,
  }),
  upgrade: Styles.createTextStyle({
    fontSize: 10,
    color: Colors.secondary.green,
  }),
  storageBar: Styles.createViewStyle({
    flex: 0,
    marginTop: 16,
    flexDirection: 'column',
  }),
  promo: Styles.createViewStyle({
    width: 200,
    height: 306,
    marginTop: 14,
  }),
  storageFooter: Styles.createViewStyle({
    flex: 0,
    flexDirection: 'row',
    marginTop: 8,
  }),
  storageLink: Styles.createTextStyle({
    fontSize: 10,
    color: Colors.secondary.green,
  }),
  legal: Styles.createViewStyle({
    flex: 0,
    paddingVertical: 8,
    marginTop: 16,
    borderTopWidth: 1,
    borderColor: 'rgba(255,255,255,0.1)',
  }),
  version: Styles.createViewStyle({
    flex: 1,
    marginBottom: 2,
    flexDirection: 'row',
  }),
  copyright: Styles.createTextStyle({
    color: Colors.neutral.light,
    textAlign: 'left',
    fontSize: 10,
    flex: 1,
  }),
  build: Styles.createTextStyle({
    color: Colors.neutral.light,
    textAlign: 'right',
    fontSize: 10,
  }),
  ccp: Styles.createLinkStyle({
    color: Colors.neutral.mid,
    textAlign: 'left',
    fontSize: 9,
    marginBottom: 2,
  }),
  bar: Styles.createViewStyle({
    flex: 1,
    height: 6,
    borderRadius: 3,
    backgroundColor: Colors.neutral.darker,
  }),
  used: Styles.createViewStyle({
    flex: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    backgroundColor: '#5D626F',
  }),
  icon: Styles.createViewStyle({
    flex: 0,
    width: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  }),
  iconMore: Styles.createViewStyle({
    marginTop: 16,
  }),
  divider: Styles.createViewStyle({
    height: 2,
    width: 168,
    marginVertical: 8,
    marginHorizontal: 16,
    backgroundColor: '#3d4350',
    overflow: 'visible',
  }),
  dividerWithTag: Styles.createViewStyle({
    marginVertical: 16,
  }),
  dividerTag: Styles.createTextStyle({
    fontSize: 12,
    fontWeight: '600',
    textAlign: 'center',
    color: '#b2b7c4',
    backgroundColor: '#282f3d',
    paddingVertical: 1,
    paddingHorizontal: 6,
    marginHorizontal: 40,
    position: 'absolute',
    top: -7,
  }),
  spacer: Styles.createViewStyle({
    flex: 1,
  }),
};
