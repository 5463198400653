import {Platform} from 'react-ult';
import {HOSTS, ORIGIN} from 'globals/config';

let _customSubdomain: string;

export function getEnvironment() {
  if (isDev())
    return 'dev';
  if (isLive() || isLocal())
    return 'www';
  if (isSandbox2())
    return 'sandbox2';
  if (isSandboxApp() || isSandbox())
    return 'sandbox';
  return 'www';
}

export function getHost(noSSL?: boolean) {
  const protocol = noSSL ? 'http' : 'https';
  if (_customSubdomain)
    return `${protocol}://${_customSubdomain}.${ORIGIN}`;
  if (isDev())
    return `${protocol}://dev.${ORIGIN}`;
  if (isLive() || isLocal())
    return `${protocol}://www.${ORIGIN}`;
  if (isSandbox2())
    return `${protocol}://sandbox2.${ORIGIN}`;
  if (isSandboxApp() || isSandbox())
    return `${protocol}://sandbox.${ORIGIN}`;
  return `${protocol}://www.${ORIGIN}`;
}

export function getClient() {
  if (isWeb()) {
    return navigator.userAgent.slice(0, 255);
  } else if (isAndroid()) {
    return 'android';
  } else if (isWindows()) {
    return 'windows';
  } else if (isMacOS()) {
    return 'macos';
  } else if (isIOS()) {
    return 'ios';
  }
  return 'unknown';
}

export function getCustomSubdomain() {
  return _customSubdomain;
}

export function setCustomSubdomain(subdomain: string) {
  _customSubdomain = subdomain;
}

export function isTouch() {
  switch (Platform.getType()) {
    case 'android': return true;
    case 'ios': return true;
    case 'macos': return false;
    case 'windows': return false;
    case 'web': return ('ontouchstart' in window
      || (navigator && navigator.maxTouchPoints > 0))
      && (isLocal() || (
        navigator.platform !== 'Win32'
        && navigator.platform !== 'Win64'
        && navigator.platform !== 'MacIntel')
    );
    default: return false;
  }
}

export function isWeb() {
  return Platform.getType() === 'web';
}

export function isNative() {
  return isAndroid() || isIOS() || isMacOS() || isWindows();
}

export function isAndroid() {
  return Platform.getType() === 'android';
}

export function isIOS() {
  return Platform.getType() === 'ios';
}

export function isMacOS() {
  return Platform.getType() === 'macos';
}

export function isWindows() {
  return Platform.getType() === 'windows';
}

export function isSafari() {
  return isWeb()
    && navigator.userAgent.indexOf('Safari/') !== -1
    && navigator.userAgent.indexOf('Chrome/') === -1;
}

export function isLive() {
  if (!isWeb()) return true;
  return window.location.origin === HOSTS.LIVE;
}

export function isDev() {
  if (!isWeb()) return false;
  return window.location.origin === HOSTS.DEV;
}

export function isLocal() {
  if (!isWeb()) return false;
  return window.location.origin === HOSTS.LOCAL
    || window.location.origin === HOSTS.PREVIEW
    || window.location.origin.indexOf('http://192.168') !== -1;
}

export function isSandbox() {
  if (!isWeb()) return false;
  return window.location.origin === HOSTS.SANDBOX
    || window.location.origin === HOSTS.SANDBOX_TWO;
}

export function isSandbox2() {
  if (!isWeb()) return false;
  return window.location.origin === HOSTS.SANDBOX_TWO;
}

export function isSandboxApp() {
  if (!isWeb()) return false;
  return window.location.origin === HOSTS.SANDBOX_APP;
}
