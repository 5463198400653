import {t} from '@lingui/macro';
import React, {useState, useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Styles, View, Text, Link, Button, Modal, Types} from 'react-ult';
import {default as Svg, SvgPath} from 'react-ult-ext-imagesvg';
import {useModalConflict} from 'hooks/useModalConflict';
import {FilesList} from 'view/FilesList';
import {IconButton} from 'controls/IconButton';
import {SafeAreaView} from 'controls/SafeArea';
import {useModalUpsellFeature} from 'hooks/useModalUpsellFeature';
import {useModalUpsellStorage} from 'hooks/useModalUpsellStorage';
import {FilesModalWebUpload, modalFilesWebUpload} from 'view/modals/FilesModalWebUpload';
import {sessionToken, alert, uploadState, uploadSync, uploadWebSync, uploadGetWebFiles} from 'features/mediafire';
import {pickFiles, pickMedia} from 'features/filesystem';
import {getHost, isWeb, isTouch, isIOS} from 'features/platform';
import {Light, Vectors, Colors} from 'features/themes';
import {bytesize} from 'features/common';
import {Uploader, UploadResolutions, UploadSession} from 'globals/upload';
import {selectors} from 'store/files';
import {state} from 'store';
import * as app from 'store/app';

import * as filesEffects from 'store/files/effects';

export interface Props {
  id: string;
  minimized: boolean;
}

export function FilesUploader(props: Props) {
  const session = useRef<UploadSession>(null);
  const [stateActivated, setActivated] = useState(false);
  const header = useSelector(app.selectors.getHeader);
  const viewport = useSelector(app.selectors.getViewport);
  const limits = useSelector(app.selectors.getLimits);
  const isPremium = useSelector(app.selectors.isPremium);
  const uploadWebIds = useSelector(selectors.getUploadWeb);
  const files = useSelector(selectors.getUploadFiles);
  const items = useSelector(selectors.getUploadItems);
  const view = useSelector(selectors.getUploadView);
  const filter = useSelector(selectors.getFilter);
  const sort = useSelector(selectors.getSort);
  const status = uploadState(items);
  const hashCurrent = status.hashed;
  const uploadCurrent = status.uploaded;
  const uploadTotal = status.size;
  const hasFiles = status.count > 0;
  const hasFolderUpload = !isTouch();
  const hasWebUpload = isPremium || !isIOS();
  const hasAborted = status.aborted === status.count && status.count > 0;
  const hasSkipped = status.skipped === status.count && status.count > 0;
  const hasButtonText = viewport.width < 530;
  const isFileDrop = false;
  const isSmall = viewport.width < 600;
  const isMinimal = viewport.width < 800 || viewport.height < 520;
  const isComplete = stateActivated && status.completed;
  const hashPercent = Math.max(0, Math.min(100, (hashCurrent / uploadTotal) * 100));
  const uploadPercent = Math.max(0, Math.min(100, (uploadCurrent / uploadTotal) * 100));
  const hashPixels = (hashCurrent / uploadTotal) * (isSmall ? viewport.width - 126 : 654);
  const uploadPixels = (uploadCurrent / uploadTotal) * (isSmall ? viewport.width - 126 : 654);
  const hashPixelsMini = (hashCurrent / uploadTotal) * (isSmall ? viewport.width - 36 : 314);
  const uploadPixelsMini = (uploadCurrent / uploadTotal) * (isSmall ? viewport.width - 36 : 314);
  const uploadHeight = isMinimal ? viewport.height - 143 : 357;
  const colorPrimary = header ? header.colors.primary : Colors.primary.brand;
  const classes = {
    root: [
      styles.root,
      props.minimized && styles.rootMinimized,
      props.minimized && isSmall && styles.rootMinimizedSmall,
    ],
    uploader: [
      isMinimal ? styles.uploaderMobile : styles.uploader,
    ],
    progressBarHashed: [
      styles.progressBarHashed,
      Styles.createViewStyle({width: isNaN(hashPixels) ? 0 : hashPixels}, false),
      isWeb() && Styles.createViewStyle({['transition' as any]: 'background-color 0.2s, width 0.5s ease'}, false),
    ],
    progressBarUploaded: [
      styles.progressBarUploaded,
      Styles.createViewStyle({width: isNaN(uploadPixels) ? 0 : uploadPixels}, false),
      isWeb() && Styles.createViewStyle({['transition' as any]: 'background-color 0.2s, width 0.5s ease'}, false),
    ],
    progressMinBar: [
      styles.progressMinBar,
      hasSkipped && styles.progressMinBarFailed,
    ],
    progressMinBarHashed: [
      styles.progressMinBarHashed,
      Styles.createViewStyle({width: isNaN(hashPixelsMini) ? 0 : hashPixelsMini}, false),
      isWeb() && Styles.createViewStyle({['transition' as any]: 'background-color 0.2s, width 0.5s ease'}, false),
    ],
    progressMinBarUploaded: [
      styles.progressMinBarUploaded,
      Styles.createViewStyle({width: isNaN(uploadPixelsMini) ? 0 : uploadPixelsMini}, false),
      isWeb() && Styles.createViewStyle({['transition' as any]: 'background-color 0.2s, width 0.5s ease'}, false),
    ],
  };

  const textFileDrop = 'Upload to my MediaFire FileDrop';
  const textSizes = `${bytesize(uploadCurrent, 2)} / ${bytesize(uploadTotal, 2)}`;
  const textCount = `${status.count} File${status.count !== 1 ? 's' : ''}`;
  const textSkipped = `${status.skipped} file${status.skipped !== 1 ? 's' : ''} not uploaded`;
  const textFinished = `${status.finished} file${status.finished !== 1 ? 's' : ''} uploaded`;
  const textUpload = uploadPercent && !isNaN(uploadPercent)
    ? (status.verifying > 0 && uploadPercent >= 100)
      ? `Verifying ${status.verifying} file${status.verifying !== 1 ? 's' : ''}…`
      : `${Math.floor(uploadPercent)}%`
    : stateActivated
      ? hasSkipped 
        ? textSkipped
        : 'Preparing…'
      : 'Queued';
  const textTitle = isComplete
    ? hasSkipped
      ? hasAborted
        ? t`Upload Skipped`
        : t`Upload Failed`
      : t`Upload Completed`
    : stateActivated
      ? t`Uploading ${textCount}`
      : status.count === 0
        ? isMinimal ? t`Select Files` : t`Select Files to Upload`
        : t`Selected ${textCount}`;

  const dispatch = useDispatch();
  const activate = useCallback(() => setActivated(true), [setActivated]);
  const noop = useCallback((e) => e.stopPropagation(), []);
  const drop = useCallback(filesEffects.dragDrop(dispatch, null, props.id, ''), [props.id]);
  const dragOver = useCallback(filesEffects.dragOver(), []);
  const minimize = useCallback(filesEffects.uploadMinimize(dispatch, true), []);
  const maximize = useCallback(filesEffects.uploadMinimize(dispatch, false), []);

  // Modals
  const [showConflict] = useModalConflict(props.id);
  const [showUpsellStorage] = useModalUpsellStorage('upload');
  const [showUpsellWebUpload] = useModalUpsellFeature('pro', 'webupload');

  // Create uploader
  useEffect(() => {
    const availableStorage = limits ? limits.storage.total - limits.storage.used : -1;
    session.current = Uploader.create(sessionToken, availableStorage);
  }, []);

  // Start the uploader
  const start = useCallback(() => {
    Uploader.start(session.current);
    activate();
  }, [session.current]);

  // Stop and close the uploader
  const close = useCallback(() => {
    Uploader.abort(session.current);
    dispatch(state.files.actions.upload({id: null}));
    Modal.dismissAll();
  }, [session.current]);

  // Add files to upload session
  const addFiles = useCallback((folder: string) => {
    return async () => {
      try {
        const files = await pickFiles();
        Uploader.addFiles(files as any, folder, session.current);
        if (isTouch()) {
          Uploader.start(session.current);
          activate();
        }
      } catch (err) {}
    }
  }, [session.current]);

  // Add files to upload session (web input)
  const addFilesInput = useCallback((folder: string) => {
    return async (e: any) => {
      Uploader.addFiles(e.target.files, folder, session.current);
      if (isTouch()) {
        Uploader.start(session.current);
        activate();
      }
    }
  }, [session.current]);

  // Add image/video to upload session
  const addMedia = useCallback((folder: string) => {
    return async () => {
      try {
        const files = await pickMedia();
        Uploader.addFiles(files as any, folder, session.current);
        if (isTouch()) {
          Uploader.start(session.current);
          activate();
        }
      } catch (err) {}
    }
  }, [session.current]);

  // Add folder of files to upload session
  const addFolder = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    Uploader.addFiles(e.target.files, props.id, session.current);
  }, [session.current]);

  // Add files from paste
  const paste = useCallback((e: ClipboardEvent) => {
    if (e.clipboardData.files && e.clipboardData.files.length)
      return Uploader.addFiles(e.clipboardData.files, props.id, session.current);
    const items = e.clipboardData.items;
    const files: File[] = [];
    for (let i = 0; i < items.length; i++) {
      try {
        const file = items[i].getAsFile();
        if (file) files.push(file);
      } catch (e) {}
    }
    if (files.length) {
      Uploader.addFiles(files, props.id, session.current);
    } else {
      const text = e.clipboardData.getData('text');
      if (text && text.match(/^(ftp|sftp|http|https)\:\/\//)) {
        webupload(text);
      }
    }
  }, [dispatch, props.id]);

  // Add web uploads
  const webupload = useCallback((url?: string | Types.SyntheticEvent) => {
    Modal.show(
      <FilesModalWebUpload
        dispatch={dispatch}
        id={props.id}
        primaryColor={colorPrimary}
        isMinimal={isMinimal}
        url={typeof url === 'string' ? url : ''}
      />
    , modalFilesWebUpload);
  }, [isMinimal]);

  // Sync upload events
  useEffect(() => {
    Uploader.addListener('progress', (_, file) => {
      uploadSync(dispatch, file);
    }, session.current);
    Uploader.addListener('complete', (_, file) => {
      uploadSync(dispatch, file);
    }, session.current);
    Uploader.addListener('conflict', (_, file) => {
      uploadSync(dispatch, file);
      showConflict((action: UploadResolutions) =>
        Uploader.handleConflict(file, session.current, action, true));
    }, session.current);
    Uploader.addListener('failure', (error, file) => {
      if (file.exceededStorage)
        showUpsellStorage();
      uploadSync(dispatch, file, error);
    }, session.current);
    Uploader.addListener('failureBatch', (error) => {
      const message = error.toString() === 'ReqError: TypeError: Failed to fetch'
        ? 'Upload interrupted due to network issues'
        : error.toString();
      Uploader.abort(session.current);
      alert(dispatch, message, 'fail');
      dispatch(state.files.actions.upload({id: null}));
      Modal.dismissAll();
    }, session.current);
  }, [props.id, session.current]);

  // Sync web uploads
  useEffect(() => {
    const timer = setInterval(() => {
      if (uploadWebIds && uploadWebIds.length > 0) {
        uploadGetWebFiles(uploadWebIds[0]).then((e: any) => {
          if (e.webUploads && e.webUploads.length > 0) {
            uploadWebSync(dispatch, props.id, e.webUploads);
            activate();
          }
        });
      }
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [props.id, uploadWebIds]);

  // Drag & drop uploads
  useEffect(() => {
    if (files) {
      Uploader.addFiles(files, props.id, session.current);
      Uploader.start(session.current);
      activate();
    }
  }, [files, props.id]);

  // Clipboard uploads
  useEffect(() => {
    if (isWeb()) {
      window.addEventListener('paste', paste);
      return () => {
        window.removeEventListener('paste', paste);
      };
    }
    return () => {};
  }, []);

  // Prevent navigation
  useEffect(() => {
    if (isWeb()) {
      window.onbeforeunload = stateActivated && !isComplete
        ? () => 'Are you sure you want to cancel your upload?'
        : null;
    }
    return () => {
      if (isWeb()) {
        window.onbeforeunload = null;
      }
    };
  }, [isComplete, stateActivated]);

  return (
    <View
      disableTouchOpacityAnimation
      style={classes.root}
      onDrop={drop}
      onDragOver={dragOver}
      onPress={!props.minimized
        ? isComplete || !hasFiles
          ? close
          : minimize
        : noop}>
        {props.minimized
          ? <View style={styles.uploaderMin}
              disableTouchOpacityAnimation
              title={t`Show uploader`}
              onPress={maximize}>
              <View style={classes.progressMinBar}>
                {!hasSkipped && <View style={classes.progressMinBarHashed} ariaValueNow={hashPercent}></View>}
                {!hasSkipped && <View style={classes.progressMinBarUploaded} ariaValueNow={uploadPercent}></View>}
              </View>
              <View style={styles.progressMinDetails}>
                <Text style={styles.progressMinDetailsText}>
                  {stateActivated && (!isComplete || status.verifying > 0)
                    ? textSizes
                    : textTitle}
                </Text>
                <Text style={styles.progressMinDetailsPercent}>
                  {textUpload}
                </Text>
              </View>
            </View>
          : <View
              restrictFocusWithin
              style={classes.uploader}
              accessibilityTraits={Types.AccessibilityTrait.Dialog}
              disableTouchOpacityAnimation={true}
              tabIndex={-1}
              onPress={noop}>
              <SafeAreaView style={{flex: 1, backgroundColor: Colors.neutral.black}}>
                <View style={styles.contents}>
                  <View style={styles.header}>
                    <Text style={styles.title}>
                      {textTitle}
                    </Text>
                    <Link url={`${getHost()}/customer_diagnosis/questionnaire.php`}>
                      <Text style={styles.diagnosticLinkText}>
                        {t`Upload Diagnostic Tool`}
                      </Text>
                    </Link>
                    {stateActivated && !isComplete
                      ? <Button style={styles.buttonClose} onPress={minimize} title={t`Minimize uploader`}>
                          <Svg viewBox="0 0 24 24" width={24} height={24}>
                            <SvgPath d={Vectors.iconMin} fillColor={Colors.neutral.mid}/>
                          </Svg>
                        </Button>
                      : <Button style={styles.buttonClose} onPress={close} title={t`Close uploader`}>
                          <Svg viewBox="0 0 14 14" width={14} height={14}>
                            <SvgPath d={Vectors.iconClose} fillColor={Colors.neutral.mid}/>
                          </Svg>
                        </Button>
                    }
                  </View>
                  {hasFiles &&
                    <FilesList
                      uploader
                      id={props.id}
                      sort={sort}
                      filter={filter}
                      name="Uploader"
                      selection={[]}
                      dragging={[]}
                      dispatch={dispatch}
                      viewport={viewport}
                      isSearch={false}
                      isPremium={isPremium}
                      maxheight={uploadHeight}
                      items={items}
                      view={view}
                    />
                  }
                  {!hasFiles &&
                    <View
                      style={styles.empty}
                      onPress={!isWeb() ? addMedia(props.id) : undefined}>
                      {isWeb() &&
                        <React.Fragment>
                          <input type="file" tabIndex={-1} onChange={addFilesInput(props.id)} multiple/><label/>
                        </React.Fragment>
                      }
                      {isFileDrop &&
                        <Text style={styles.fileDrop}>
                          {textFileDrop}
                        </Text>
                      }
                      {isWeb() && !isTouch() &&
                        <View style={styles.emptyIcon}>
                          <Svg viewBox="0 0 14 14" width={64} height={64}>
                            <SvgPath d={Vectors.iconAdd} fillColor={Light.uploaderContentIcon}/>
                          </Svg>
                        </View>
                      }
                      {isTouch() &&
                        <Text style={styles.touchHint}>
                          {t`Tap here to browse media to upload`}
                        </Text>
                      }
                    </View>
                  }
                  <View style={styles.actions}>
                    {isComplete &&
                      <Button style={[styles.buttonUpload, Styles.createButtonStyle({cursor: 'default'}, false)]}>
                        <Svg viewBox="0 0 20 20" width={20} height={20}>
                          <SvgPath
                            d={status.skipped === 0 ? Vectors.iconCheck : Vectors.iconWarning}
                            fillColor={status.skipped === 0 ? Colors.secondary.green : Colors.secondary.redOrange}
                          />
                        </Svg>
                        <Text style={styles.completeMessage}>
                          {status.skipped === 0 ? textFinished : textSkipped}
                        </Text>
                      </Button>
                    }
                    {!stateActivated &&
                      <View style={styles.input}>
                        {isWeb() &&
                          <React.Fragment>
                            <input type="file" tabIndex={-1} onChange={addFilesInput(props.id)} multiple/><label/>
                          </React.Fragment>
                        }
                        <Button
                          style={styles.buttonUpload}
                          onPress={addFiles(props.id)}
                          title="Choose file(s) to upload">
                          <Svg viewBox="0 0 24 24" width={24} height={24}>
                            <SvgPath d={Vectors.iconFileAdd} fillColor={Light.uploaderBarText} fillOpacity={0.8}/>
                          </Svg>
                          {!hasButtonText &&
                            <Text style={styles.buttonUploadText}>
                              {t`Add file`}
                            </Text>
                          }
                        </Button>
                      </View>
                    }
                    {hasFolderUpload && !stateActivated &&
                      <View style={styles.input}>
                        {isWeb() &&
                          <React.Fragment>
                            <input
                              type="file"
                              tabIndex={-1}
                              onChange={addFolder}
                              // @ts-ignore
                              directory={'true'}
                              webkitdirectory={'true'}
                              mozdirectory={'true'}
                            /><label/>
                          </React.Fragment>
                        }
                        <Button
                          title={t`Choose a folder to upload`}
                          onPress={addFiles(props.id)}
                          style={styles.buttonUpload}>
                          <Svg viewBox="0 0 24 18" width={24} height={18}>
                            <SvgPath d={Vectors.iconFolderAdd} fillColor={Light.uploaderBarText} fillOpacity={0.8}/>
                          </Svg>
                          {!hasButtonText &&
                            <Text style={styles.buttonUploadText}>
                              {t`Add folder`}
                            </Text>
                          }
                        </Button>
                      </View>
                    }
                    {hasWebUpload && !stateActivated &&
                      <Button
                        title={t`Upload a file from the web`}
                        style={styles.buttonUpload}
                        onPress={isPremium ? webupload : showUpsellWebUpload}>
                        <Svg viewBox="0 0 20 20" width={20} height={20}>
                          <SvgPath d={Vectors.iconWeb} fillColor={Light.uploaderBarText} fillOpacity={0.8}/>
                        </Svg>
                        {!hasButtonText &&
                          <Text style={styles.buttonUploadTextWeb}>
                            {t`Web Upload`}
                          </Text>
                        }
                      </Button>
                    }
                    {stateActivated && (hasSkipped || isComplete) &&
                      <View style={styles.spacer}/>
                    }
                    {stateActivated && !isComplete && !hasSkipped &&
                      <View style={styles.progress}>
                        <View style={styles.progressBar}>
                          <View style={classes.progressBarHashed} ariaValueNow={hashPercent}></View>
                          <View style={classes.progressBarUploaded} ariaValueNow={uploadPercent}></View>
                        </View>
                        <View style={styles.progressDetails}>
                          <Text style={styles.progressDetailsText}>{textSizes}</Text>
                          <Text style={styles.progressDetailsPercent}>{textUpload}</Text>
                        </View>
                      </View>
                    }
                    <View style={!stateActivated ? styles.buttonsQueue : styles.buttonsActive}>
                      {!stateActivated && !isComplete &&
                        <React.Fragment>
                          <IconButton
                            mode="primary"
                            disabled={!hasFiles}
                            title={t`Begin upload`}
                            text={isMinimal ? t`UPLOAD` : t`BEGIN UPLOAD`}
                            onPress={hasFiles ? start : undefined}
                          />
                          <View style={styles.spacerH}/>
                        </React.Fragment>
                      }
                      <IconButton
                        mode="cancel"
                        title={t`Close uploader`}
                        text={isComplete ? t`CLOSE` : `CANCEL`}
                        onPress={close}
                      />
                    </View>
                  </View>
                </View>
              </SafeAreaView>
            </View>
        }
      </View>
  );
}

export const styles = {
  // Containers
  root: Styles.createViewStyle({
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Light.dialogBehind,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
  rootMinimized: Styles.createViewStyle({
    width: 350,
    height: 65,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    backgroundColor: Colors.neutral.darker,
    shadowOffset: {width: -1, height: -1},
    shadowColor: 'rgba(0,0,0,0.3)',
    shadowRadius: 3,
    elevation: 1,
    top: undefined,
    left: undefined,
    bottom: 0,
    right: 16,
  }),
  rootMinimizedSmall: Styles.createViewStyle({
    left: 0,
    right: 0,
    width: undefined,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),
  uploader: Styles.createViewStyle({
    width: 780,
    height: 500,
    borderRadius: 3,
    backgroundColor: Light.uploaderContentBackground,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'rgba(0,0,0,0.3)',
    shadowRadius: 3,
    elevation: 1,
  }),
  uploaderMobile: Styles.createViewStyle({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: Light.uploaderContentBackground,
  }),
  uploaderMin: Styles.createViewStyle({
    flex: 1,
    paddingTop: 21,
    paddingHorizontal: 18,
    paddingBottom: 19,
    cursor: 'pointer',
    flexDirection: 'column',
  }),
  diagnosticLinkText: Styles.createTextStyle({
    fontSize: 14,
    color: Light.diagnosticUploadTool,
  }),
  // Sections
  header: Styles.createViewStyle({
    flexDirection: 'row',
    alignItems: 'center',
    height: 54,
    borderBottomWidth: 1,
    borderColor: Light.uploaderBarBorder,
    backgroundColor: Light.uploaderBarBackground,
  }),
  contents: Styles.createViewStyle({
    flex: 1,
    backgroundColor: Light.uploaderContentBackground,
  }),
  actions: Styles.createViewStyle({
    flexDirection: 'row',
    height: 62,
    paddingRight: 16,
    borderTopWidth: 1,
    borderColor: Light.uploaderBarBorder,
    backgroundColor: Light.uploaderBarBackground,
  }),
  // Empty
  empty: Styles.createViewStyle({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  emptyIcon: Styles.createViewStyle({
    width: 180,
    height: 180,
    borderWidth: 3,
    borderRadius: 5,
    borderStyle: 'dashed',
    borderColor: Light.uploaderContentIcon,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  // Texts
  title: Styles.createTextStyle({
    color: Light.uploaderBarTitle,
    paddingLeft: 20,
    fontSize: 16,
    flex: 1,
  }),
  fileDrop: Styles.createTextStyle({
    marginBottom: 16,
    fontSize: 20,
    fontWeight: '600',
    color: Light.uploaderBarText,
  }),
  touchHint: Styles.createTextStyle({
    marginBottom: 32,
    fontSize: 16,
    fontWeight: '500',
    color: Light.uploaderBarText,
  }),
  completeMessage: Styles.createTextStyle({
    color: Light.uploaderBarText,
    marginLeft: 8,
    fontSize: 14,
  }),
  // Buttons
  buttonsQueue: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row-reverse',
    alignItems: 'center',
  }),
  buttonsActive: Styles.createViewStyle({
    flex: 0,
    flexDirection: 'row-reverse',
    alignItems: 'center',
  }),
  buttonClose: Styles.createButtonStyle({
    alignItems: 'center',
    width: 54,
    height: 54,
    top: 1,
  }),
  buttonUpload: Styles.createButtonStyle({
    marginRight: 6,
    marginLeft: 16,
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
  }),
  buttonUploadText: Styles.createTextStyle({
    color: Light.uploaderBarText,
    marginLeft: 4,
    fontSize: 14,
  }),
  buttonUploadTextWeb: Styles.createTextStyle({
    color: Light.uploaderBarText,
    marginHorizontal: 7,
    marginBottom: 1,
    fontSize: 14,
  }),
  // Progress
  progress: Styles.createViewStyle({
    flex: 1,
    marginLeft: 18,
    marginRight: 16,
  }),
  progressDetails: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
    marginTop: 8,
  }),
  progressDetailsText: Styles.createTextStyle({
    color: Light.uploaderBarText,
    fontSize: 12,
    flex: 1,
  }),
  progressDetailsPercent: Styles.createTextStyle({
    color: Light.uploaderBarText,
    fontSize: 12,
    flex: 0,
  }),
  progressBar: Styles.createViewStyle({
    flex: 0,
    height: 6,
    marginTop: 16,
    borderRadius: 3,
    backgroundColor: '#d6d6d6',
  }),
  progressBarHashed: Styles.createViewStyle({
    flex: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    backgroundColor: Colors.neutral.light,
  }),
  progressBarUploaded: Styles.createViewStyle({
    flex: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    backgroundColor: Colors.primary.brand,
  }),
  progressMinDetails: Styles.createViewStyle({
    flex: 1,
    flexDirection: 'row',
    marginTop: 5,
  }),
  progressMinDetailsText: Styles.createTextStyle({
    fontSize: 12,
    flex: 1,
    color: Colors.neutral.lighter,
  }),
  progressMinDetailsPercent: Styles.createTextStyle({
    fontSize: 12,
    flex: 0,
    color: Colors.neutral.lighter,
  }),
  progressMinBar: Styles.createViewStyle({
    position: 'relative',
    height: 6,
    borderRadius: 3,
    backgroundColor: '#424E65',
  }),
  progressMinBarFailed: Styles.createViewStyle({
    backgroundColor: Colors.secondary.redOrange,
  }),
  progressMinBarHashed: Styles.createViewStyle({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    backgroundColor: Colors.neutral.mid,
  }),
  progressMinBarUploaded: Styles.createViewStyle({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    backgroundColor: Colors.primary.brand,
  }),
  // Helpers
  input: Styles.createViewStyle({
    flex: 0,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  spacer: Styles.createViewStyle({
    flex: 1,
  }),
  spacerH: Styles.createViewStyle({
    width: 6,
  }),
};
