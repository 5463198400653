import UploadWorker from 'worker-loader!./lib/upload/upload.worker';
import type {UploadInfo, UploadEventProgress, UploadEventCompleted} from './lib/upload/types';

const workers: {[id:string]: Worker} = {};

export async function uploadFile(
  file: File,
  url: string,
  info: UploadInfo,
  progress: (e: UploadEventProgress) => void,
): Promise<UploadEventCompleted> {
  return new Promise((resolve, reject) => {
    const id = info.id;
    const upload = workers[id] = new UploadWorker();
    upload.onmessage = (event) => {
      switch (event.data.type) {
        case 'mf::upload::progress':
          progress(event.data.payload);
          break;
        case 'mf::upload::completed':
          resolve(event.data.payload);
          upload.terminate();
          break;
        case 'mf::upload::cancelled':
        case 'mf::upload::failure':
          reject(event.data.payload);
          upload.terminate();
          delete workers[id];
          break;
      }
    };
    upload.onerror = (e) =>
      reject(e.message ? e : new Error('Upload worker failed'));
    upload.postMessage({
      type: 'mf::upload::start',
      payload: {file, url, info},
    });
  });
}

export async function cancelUpload(id: string) {
  const upload = workers[id];
  if (upload) {
    upload.postMessage({
      type: 'mf::upload::cancel',
      payload: {id},
    });
  }
}
