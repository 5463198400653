import Bugsnag, {NotifiableError, OnErrorCallback, Event} from '@bugsnag/js';
import amplitude from 'amplitude-js';
import * as FullStory from '@fullstory/browser';
import {isLive} from 'features/platform';
import {KEYS} from 'globals/config';
import {AppUserGroup} from 'globals/types';
import {AnalyticsBase} from './models';

const gtag = typeof window['gtag'] !== 'undefined'
  ? window['gtag']
  : () => {};

const loaded = {
  amplitude: false,
  fullstory: false,
  bugsnag: false,
  gtag: false,
};

const user = {id: '', group: AppUserGroup.Unregistered}

class Analytics implements AnalyticsBase {
  start(appName: string, userId: string, userGroup: AppUserGroup) {
    user.id = userId;
    user.group = userGroup;

    // Bugsnag
    try {
      Bugsnag.start({
        apiKey: KEYS.BUGSNAG,
        appVersion: process.env.ULT_APP_VERSION,
        appType: process.env.ULT_APP_STAGE,
        user: userId ? {id: userId} : undefined,
        context: 'myfiles',
        maxBreadcrumbs: 50,
        enabledBreadcrumbTypes: ['log', 'user', 'state', 'error', 'manual', 'process', 'request'],
        onError: (event: Event) => {
          event.addMetadata('services', {
            fullstory: loaded.fullstory
              ? FullStory.getCurrentSessionURL(true)
              : null,
          })
        },
      });
      loaded.bugsnag = true;
    } catch (e) {}

    // Amplitude
    try {
      const amp = amplitude.getInstance();
      amp.init(isLive()
        ? KEYS.AMPLITUDE_PRODUCTION
        : KEYS.AMPLITUDE_DEVELOPMENT);
      if (userId) amp.setUserId(userId);
      amp.setUserProperties({group: userGroup});
      loaded.amplitude = true;
    } catch (e) {}

    // Google Analytics
    try {
      gtag('config', {
        user_id: userId,
        dimension7: appName,
        dimension1: userGroup < AppUserGroup.Free
          ? 'unregistered'
          : userGroup === AppUserGroup.Free
            ? 'basic'
            : 'premium',
      });
      loaded.gtag = true;
    } catch (e) {}
  }

  amplitude(name: string, params?: {[key: string]: any}) {
    if (!loaded.amplitude) return;
    try {
      amplitude.getInstance().logEvent(name, params);
    } catch (e) {}
  }

  gtag(name: string, params?: {[key: string]: any}) {
    if (!loaded.gtag) return;
    try {
      gtag('event', name, params);
    } catch (e) {}
  }

  screen(name: string) {
    if (!loaded.gtag) return;
    try {
      gtag('event', 'screen_view', {screen_name: name});
    } catch (e) {}
  }

  log(value: string) {
    loaded.bugsnag && Bugsnag.leaveBreadcrumb(value);
    loaded.fullstory && FullStory.log('log', value);
  }

  notify(error: NotifiableError, onError?: OnErrorCallback) {
    if (!loaded.bugsnag) return;
    Bugsnag.notify(error, onError);
  }

  record() {
    if (loaded.fullstory) return;
    loaded.fullstory = true;
    FullStory.init({
      orgId: '1QP6C',
      recordCrossDomainIFrames: true,
    });
    if (user.id) {
      FullStory.identify(user.id, {
        premium_bool: user.group > AppUserGroup.Free,
      });
    }
  }
}

export default new Analytics();
