export const VERSION = '3.2.0';
export const ORIGIN = 'mediafire.com';
export const SCOPE = '/';
export const HOSTS = {
  LIVE: `https://www.${ORIGIN}`,
  SANDBOX: `https://sandbox.${ORIGIN}`,
  SANDBOX_TWO: `https://sandbox2.${ORIGIN}`,
  SANDBOX_APP: `https://app-sandbox.${ORIGIN}`,
  DEV: `https://app-dev.${ORIGIN}`,
  PREVIEW: `http://local.${ORIGIN}`,
  LOCAL: `http://localhost:3000`,
};

export const LOCALES = {
  DEFAULT: 'en',
  SUPPORTED: {
    en: "English",
    es: "Español",
    de: "Deutsch",
    ar: "العربية",
    pt: "Português",
    id: "Bahasa Indonesia",
  },
};

export const KEYS = {
  BUGSNAG: 'f38f920c0646f792f006301974020325',
  AMPLITUDE_PRODUCTION: '28916b6cd60c79c0447b3c23ad698c98',
  AMPLITUDE_DEVELOPMENT: '9956c29ab0f3f042369ed79484581c28',
};
