import {t} from '@lingui/macro';
import MediaFire from 'mediafire';
import {FolderContent} from 'mediafire/dist/types/Folder';
import {Dispatch, AnyAction} from '@reduxjs/toolkit';
import {Linking, Accessibility, Clipboard} from 'react-ult';
import {AppAlertType, AppHeader, AppUserGroup} from 'globals/types';
import {lightFormat as formatTimestamp, formatDistance} from 'date-fns';
import {getHost, getEnvironment, getClient, setCustomSubdomain, isWeb, isNative, isLocal} from 'features/platform';
import {viewFile, clearCookies} from 'features/filesystem';
import {bytesize, testBit, shuffle} from 'features/common';
import analytics from 'features/analytics';
import device from 'features/device';
import {Vectors, Light} from 'features/themes';
import {AppFeatures, FilesStore, FilesItem, FilesErrors, FilesItemFlag} from 'globals/types';
import {FileUpload, FileState} from 'globals/upload';
import {HOSTS} from 'globals/config';
import {state, history} from 'store';

let _sessionCookies: any;
let _clearAlertsTimer: NodeJS.Timeout;
let _syncNotificationsTimer: NodeJS.Timeout;
let _syncFolderTimer: NodeJS.Timeout;

export let api = new MediaFire(getHost());
export let sessionToken = isLocal() ? process.env.ULT_APP_TOKEN : '';
export let sessionEmail = '';
export let sessionPkey = '';
export let sessionEkey = '';

const _features: AppFeatures[] = ['Request', 'Send', 'Sell', 'Deliver', 'Insights'];
export const featuresList = [..._features]; shuffle(featuresList);
export const featuresOrder = featuresList.map(f => _features.indexOf(f)).join();

// GENERAL

export async function login(dispatch: Dispatch<AnyAction>, cookies?: any) {
  _sessionCookies = cookies;
  await fetchSession();
  try {
    if (sessionToken) {
      authSession();
      const [header, apiUser, apiAvatar] = await Promise.all([
        fetchCustomization(),
        api.user.getInfo(),
        api.user.getAvatar(),
      ]);

      const {ekey, email, options, created, premium, displayName} = apiUser.userInfo;
      const group = apiUser.userInfo['oneTimeKeyRequestMaxCount'] === 40000
        ? AppUserGroup.Business
        : premium
          ? AppUserGroup.Pro
          : email
            ? AppUserGroup.Free
            : AppUserGroup.Trial;

      const avatar = apiAvatar ? apiAvatar.avatar : undefined;
      const limit = {storage: {used: apiUser.userInfo['usedStorageSize'], total: apiUser.userInfo['storageLimit']}};
      const payload = {ekey, email, group, limit, avatar, created, options, header, name: displayName};

      if (header.business)
        setCustomSubdomain(header.business.subdomain);

      // Update app state
      dispatch(state.app.actions.login(payload));
      sessionEmail = email;

      // Notifications
      updateNotifications(dispatch);
      clearTimeout(_syncNotificationsTimer);
      _syncNotificationsTimer = setInterval(async() => {
        updateNotifications(dispatch);
      }, 15 * 1000);

      // Session renewal
      setInterval(async() => {
        renewSession();
      }, 4 * 60 * 1000);

      // Return user details
      return {ekey, email, premium, group};
    }
  } catch(e) {
    return false;
  }
  return false;
}

export async function alert(dispatch: Dispatch<AnyAction>, text: string, type: AppAlertType) {
  dispatch(state.app.actions.alert({text, type}));
  // We don't support RTL, announce will flash this layout
  // remove this condition when RTL is fully supported for Arabic
  if (device.getLocale(true) !== 'ar')
    Accessibility.announceForAccessibility(text);
  clearTimeout(_clearAlertsTimer);
  _clearAlertsTimer = setTimeout(() => dispatch(state.app.actions.clearAlerts()), 5000);
}

export async function updateNotifications(dispatch: Dispatch<AnyAction>) {
  try {
    const path = `${getHost()}/api/1.5/notifications/peek_cache.php`;
    const options = {excludeEndpointUrl: true};
    const notifications = await api.device.post(path, undefined, options);
    const count = notifications ? parseInt(notifications['numUnread']) : 0;
    dispatch(state.app.actions.updateNotifications({count}));
  } catch (e) {
    const error = e?.body?.response?.error;
    if (error === 105 || error === 104)
      fetchSession(true);
    return undefined;
  }
}

export async function fetchCustomization(): Promise<AppHeader> {
  const defaultHeader = {
    needsPaymentUpdate: false,
    customAlertIDs: [],
    business: undefined,
    custom: false,
    logo: '',
    colors: {
      header: Light.logoColor,
      primary: Light.logoColor,
    },
    theme: {
      header: 'default',
    },
  };
  try {
    const path = `${getHost()}/application/customization.php`;
    const options = {excludeEndpointUrl: true, withCredentials: true, retries: 5};
    const response = await api.user.post(path, undefined, options);
    return response ? response as AppHeader : defaultHeader;
  } catch (e) {
    return defaultHeader;
  }
}

export async function fetchSession(redirect?: boolean) {
  if (!api) {
    api = new MediaFire(getHost());
  }

  // Build cookie header
  let cookie = [];
  if (isNative() && _sessionCookies) {
    Object.keys(_sessionCookies).forEach((key: string) => {
      cookie.push(`${encodeURIComponent(key)}=${encodeURIComponent(_sessionCookies[key])}`)
    });
  }

  // Build url
  const url = `${getHost()}/application/get_session_token.php`;
  const query = isNative() ? `?platform=${getClient()}` : '';

  try {
    // Fetch session token
    const request = await fetch(`${url}${query}`, {
      credentials: isNative() ? 'omit' : 'include',
      method: 'post',
      headers: {
        'Cookie': isNative() && cookie.length > 0
          ? cookie.join('; ')
          : undefined,
      }
    });
    // Parse response
    const session = await request.json();
    if (session && session.response) {
      if (session.response.session_token)
        sessionToken = session.response.session_token;
      if (session.response.permanent_token)
        sessionPkey = session.response.permanent_token;
      if (session.response.ekey)
        sessionEkey = session.response.ekey;
      authSession();
      return true;
    }
  } catch (e) {}
  redirect && gotoHomepage();
  return false;
}

export async function openPage(url: string, skipAuth?: boolean) {
  if (!isNative() || skipAuth) {
    openUrl(url);
  } else if (isNative() && !skipAuth) {
    const path = `${getHost()}/api/1.5/user/get_login_token_v2.php`;
    const options = {excludeEndpointUrl: true};
    try {
      const response = await api.device.post(path, {
        password: sessionPkey,
        email: sessionEmail,
        ekey: sessionEkey,
        duration: 'session',
        redirectPath: url,
        platform: getClient(),
      }, options);
      if (response && response['loginToken']) {
        const token = response['loginToken'];
        const redirect = `${getHost()}/dynamic/client_login/token.php?login_token=${token}`;
        openUrl(redirect);
      }
    } catch (e) {
      openUrl(url);
    }
  }
}

export async function openUrl(url: string) {
  try {
    await Linking.openUrl(url);
  } catch(e) {
    try {location.href = url;} catch(e) {}
  }
}

export async function renewSession() {
  try {
    const path = `${getHost()}/api/1.5/user/renew_session_token.php`;
    const options = {excludeEndpointUrl: true};
    const response = await api.device.post(path, {sessionToken}, options);
    if (response && response['sessionToken'] && response['sessionToken'] !== sessionToken) {
      sessionToken = response['sessionToken'];
      authSession();
    }
  } catch (e) {
    const error = e?.body?.response?.error;
    if (error === 105 || error === 104)
      fetchSession(true);
    return undefined;
  }
}

export async function destroySession(dispatch: Dispatch<AnyAction>) {
  try {
    const path = `${getHost()}/application/logout.php`;
    const options = {excludeEndpointUrl: true, withCredentials: true, retries: 5};
    await api.user.post(path, undefined, options);
  } catch (e) {}
  _sessionCookies = '';
  sessionToken = '';
  dispatch(state.app.actions.logout());
  clearCookies();
  if (isWeb()) {
    gotoHomepage();
  } else {
    device.restartApp();
  }
}

export function authSession() {
  api.authenticate(sessionToken);
}

export function gotoHomepage() {
  if (isWeb()) {
    window.location.href = getHost();
  }
}

export function trackShare() {
  analytics.gtag('share');
}

export function fail(id: string, e: any) {
  const code = e && e.body && e.body.response ? e.body.response.error : 0;
  switch (code) {
    case FilesErrors.InvalidToken:
    case FilesErrors.MissingToken:
      // fetchSession(true);
      gotoHomepage();
      break;
    case FilesErrors.InvalidFolderkey:
    case FilesErrors.MissingFolderkey:
    case FilesErrors.AccessDenied:
      window.location.href = `${getHost()}/error.php?errno=999&quickkey=${id}`;
      break;
    default:
      console.log('error:', code, e);
      break;
  }
}

// MANAGEMENT

export async function move(dispatch: Dispatch<AnyAction>, ids: string[], destination: string, name: string) {
  try {
    const files = [];
    const folders = [];
    if (ids.length > 0) {
      ids.forEach(id => isFolderkey(id) ? folders.push(id) : files.push(id));
      await Promise.all([
        files.length && api.file.move(files.join(), destination),
        folders.length && api.folder.move(folders.join(), destination),
      ]);
      dispatch(state.files.actions.move({ids, destination}));
      const message = `Moved ${ids.length} ${ids.length === 1 ? 'item' : 'items'} to “${name}”`;
      alert(dispatch, message, 'info');
      analytics.gtag('move');
      return true;
    }
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function copy(dispatch: Dispatch<AnyAction>, ids: string[], destination: string, name: string) {
  try {
    const files = [];
    const folders = [];
    if (ids.length > 0) {
      ids.forEach(id => isFolderkey(id) ? folders.push(id) : files.push(id));
      await Promise.all([
        files.length && api.file.post('copy.php', {quickKey: files.join(), folderKey: destination}),
        folders.length && api.folder.post('copy.php', {folderKeySrc: folders.join(), folderKeyDst: destination}),
      ]);
      dispatch(state.files.actions.copy({ids, destination}));
      const message = t`Copied ${ids.length} ${ids.length === 1 ? 'item' : 'items'} to “${name}”`;
      alert(dispatch, message, 'info');
      analytics.gtag('copy');
      return true;
    }
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function restore(dispatch: Dispatch<AnyAction>, ids: string[], destination: string, name: string) {
  try {
    const files = [];
    const folders = [];
    if (ids.length > 0) {
      ids.forEach(id => isFolderkey(id) ? folders.push(id) : files.push(id));
      await Promise.all([
        files.length && api.file.move(files.join(), destination),
        folders.length && api.folder.move(folders.join(), destination),
      ]);
      dispatch(state.files.actions.move({ids, destination}));
      const message = t`Restored ${ids.length} ${ids.length === 1 ? 'item' : 'items'} to “${name}”`;
      alert(dispatch, message, 'info');
      analytics.gtag('restore');
      return true;
    }
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function archive(dispatch: Dispatch<AnyAction>, ids: string[]) {
  try {
    analytics.gtag('archive');
    const files = ids.filter(id => isQuickkey(id));
    const folders = ids.filter(id => isFolderkey(id));
    dispatch(state.files.actions.select({ids: []}));
    return await Promise.all([
      files.length > 0 && api.file.post('delete.php', {quickKey: files.join()}),
      folders.length > 0 && api.folder.post('delete.php', {folderKey: folders.join()}),
    ]);
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function purge(dispatch: Dispatch<AnyAction>, ids: string[]) {
  try {
    analytics.gtag('purge');
    const files = ids.filter(id => isQuickkey(id));
    const folders = ids.filter(id => isFolderkey(id));
    dispatch(state.files.actions.select({ids: []}));
    return await Promise.all([
      files.length > 0 && api.file.post('purge.php', {quickKey: files.join()}),
      folders.length > 0 && api.folder.post('purge.php', {folderKey: folders.join()}),
    ]);
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function rename(id: string, name: string) {
  try {
    analytics.gtag('rename');
    const isFolder = isFolderkey(id);
    const fileType = isFolderkey(id) ? 'folder' : 'file';
    return await api[fileType].post('update.php', isFolder
      ? {folderKey: id, foldername: name}
      : {quickKey: id, filename: name});
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function describe(id: string, description: string) {
  try {
    analytics.gtag('description');
    const isFolder = isFolderkey(id);
    const fileType = isFolderkey(id) ? 'folder' : 'file';
    return await api[fileType].post('update.php', isFolder
      ? {description, folderKey: id}
      : {description, quickKey: id});
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function privacy(ids: string[], state: 'public' | 'private') {
  analytics.gtag('private');
  ids.forEach(async id => {
    const isFolder = isFolderkey(id);
    const fileType = isFolderkey(id) ? 'folder' : 'file';
    try {
      api[fileType].post('update.php', isFolder
        ? {privacy: state, folderKey: id}
        : {privacy: state, quickKey: id});
    } catch(e) {
      return e && e.body ? e.body.response : e;
    }
  });
}

export async function password(id: string, password: string) {
  try {
    analytics.gtag('password');
    const isFolder = isFolderkey(id);
    const fileType = isFolderkey(id) ? 'folder' : 'file';

    // Workaround to fix remove password due to SDK not sending empty string
    if (!password) {
      try {
        const query = `password=${password ? password : ""}&quick_key=${id}&session_token=${sessionToken}`;
        const url = `${getHost()}/api/1.5/file/update_password.php?${query}&response_type=json`;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.send();
        return {result: 'Success'};
      } catch (e) {
        return {result: 'Error'};
      }
    }

    return await api[fileType].post('update_password.php', isFolder
      ? {folderKey: id, password}
      : {quickKey: id, password});
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function download(url: string, bulk?: boolean, forceDownload?: boolean) {
  if (!url) return;
  const base = bulk ? url : url.replace(/^http:\/\//g, 'https://');
  const path = forceDownload && base ? base.replace('/view/', '/file/') : base;
  try {
    analytics.gtag(bulk ? 'bulk_download' : 'download');
    if (bulk && isWeb()) {
      location.href = path;
    } else if (isWeb() && path && path.indexOf('/view/') === -1) {
      window.open(path);
    } else if (path) {
      openPage(path);
    }
  } catch(e) {
    try {location.href = path;} catch(e) {}
  }
}

export async function preview(id: string, name: string, dispatch: Dispatch, actions: any) {
  try {
    const params = {quickKey: id, linkType: 'direct_download'};
    const response: any = await api.file.post('get_links.php', params);
    return viewFile(response.links[0].directDownload, name, dispatch, actions);
  } catch(e) {
    return false;
  }
}

export async function bulkDownload(ids: string[], statusKey: string, confirmBandwidth?: boolean, confirmLargeDownload?: boolean) {
  try {
    const keys = ids.join();
    const meta = await api.file.post('zip_preview.php', {
      keys,
      statusKey,
      metaOnly: 'yes',
      useOwnBandwidth: confirmBandwidth ? 'yes' : 'no',
      allowLargeDownload: confirmLargeDownload ? 'yes' : 'no',
    });
    const authed = sessionToken ? `&session_token=${sessionToken}` : '';
    const query = `use_own_bandwidth=yes&allow_large_download=yes&keys=${keys}&status_key=${statusKey}${authed}`;
    download(`${getHost()}/api/1.5/file/zip.php?${query}`, true);
    return meta;
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function bulkDownloadPreview(ids: string[]) {
  try {
    return await api.file.post('zip_preview.php', {
      keys: ids.join(),
      useOwnBandwidth: 'yes',
      allowLargeDownload: 'yes',
    });
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function bulkDownloadStatus(id: string) {
  try {
    return await api.file.post('zip_status.php', {
      statusKey: id,
      useOwnBandwidth: 'yes',
      allowLargeDownload: 'yes',
    });
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function bulkDownloadAdd(
  dispatch: Dispatch,
  item: {
    statusKey: string,
    fileName: string,
    totalSize: number,
  },
) {
  dispatch(state.files.actions.bulkDownload(item));
}

export async function createFolder(id: string, name: string) {
  try {
    analytics.gtag('create_folder');
    return await api.folder.post('create.php', {
      parentKey: id,
      foldername: name,
    });
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function configureFileDrop(
  action: 'enable' | 'disable' | 'view',
  folderKey: string,
  message?: string,
  embedSize?: string,
  notificationEmails?: string,
) {
  try {
    analytics.gtag('configure_filedrop');
    return await api.folder.post('configure_filedrop.php', {
      action,
      folderKey,
      message,
      embedSize,
      notificationEmails,
    });
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

// Navigate

export async function load(
  dispatch: Dispatch<AnyAction>,
  id: string,
  init?: boolean,
  sort?: FilesStore['sort'],
  filter?: FilesStore['filter'],
) {
  switch (id) {
    case 'recent':
      return await loadRecent(dispatch);
    case 'following':
      return await loadFollowing(dispatch);
    case 'trash':
      return await loadTrash(dispatch);
    default:
      return await loadFolder(dispatch, id, init, false, false, sort, filter);
  }
}

export async function loadFolder(
  dispatch: Dispatch<AnyAction>,
  id: string,
  isInit?: boolean,
  isSync?: boolean,
  gallery?: boolean,
  sort?: FilesStore['sort'],
  filter?: FilesStore['filter'],
) {
  try {
    // Sorting
    let orderBy: 'name' | 'size' | 'created' | 'downloads';
    const orderDir = sort ? sort.order : 'asc';
    if (sort) {
      switch (sort.category) {
        case 'name':
          orderBy = 'name';
          break;
        case 'size':
          orderBy = 'size';
          break;
        case 'date':
          orderBy = 'created';
          break;
        case 'downloads':
          orderBy = 'downloads';
          break;
      }
    }

    // Filtering
    let filterBy: 'application' | 'archive' | 'audio' | 'development' | 'document' | 'image' | 'presentation' | 'spreadsheet' | 'video';
    let filesOnly = false;
    let foldersOnly = false;
    if (filter) {
      switch (filter.type) {
        case 'all':
          filterBy = undefined;
          break;
        case 'files':
          filterBy = undefined;
          filesOnly = true;
          break;
        case 'folders':
          filterBy = undefined;
          foldersOnly = true;
          break;
        case 'private':
          filterBy = 'private' as any; // TODO: update MF-JS-SDK
          break;
        case 'public':
          filterBy = 'public' as any; // TODO: update MF-JS-SDK
          break;
        case 'video':
          filterBy = 'video';
          break;
        case 'audio':
          filterBy = 'audio';
          break;
        case 'images':
          filterBy = 'image';
          break;
        case 'document':
          filterBy = 'document';
          break;
        case 'presentation':
          filterBy = 'presentation';
          break;
        case 'spreadsheet':
          filterBy = 'spreadsheet';
          break;
        case 'development':
          filterBy = 'development';
          break;
      }
    }

    // Retrieve data
    const apiFolder = await api.folder.getInfo(id, 'yes');
    const {name, flag, revision, fileCount, folderCount, totalFiles, totalFolders} = apiFolder.folderInfo;
    const isOwned = testBit(flag, FilesItemFlag.isOwned);
    const folderRoot = isOwned ? {id: 'myfiles', name: 'My Files'} : {id, name};
    const [apiDepth, apiFolders, apiFiles] = await Promise.all([
      isOwned && id !== 'myfiles' && !isSync && api.folder.getDepth(id),
      !filesOnly && totalFolders > 0 &&
        api.folder.getContent(id, 'folders', 1, 100, 'yes', orderDir, orderBy, filterBy),
      !foldersOnly && totalFiles > 0 &&
        api.folder.getContent(id, 'files', 1, 100, 'yes', orderDir, orderBy, gallery ? 'image' : filterBy),
    ]);

    // Dispatch load
    const details = apiFolder;
    const depth = isOwned && apiDepth && apiDepth['folderDepth'];
    const root = isInit && folderRoot;
    const files = apiFiles.folderContent && apiFiles.folderContent.files as any;
    const folders = apiFolders.folderContent && apiFolders.folderContent.folders as any;
    dispatch(state.files.actions.load({id, root, depth, details, files, folders}));

    // Load more
    const moreFiles = apiFiles && apiFiles.folderContent.moreChunks;
    const moreFolders = apiFolders && apiFolders.folderContent.moreChunks;
    const moreItems = moreFolders || moreFiles;
    moreItems && crawlFolder(dispatch, id, 2, moreFolders, moreFiles);

    // Sync folder until we leave it
    !isSync && syncFolder(dispatch, id, revision, fileCount, folderCount, sort);
  } catch(e) {
    fail(id, e);
  }
}

export async function loadTrash(dispatch: Dispatch<AnyAction>, nochunks?: boolean) {
  try {
    // Retrieve data
    const [folders, files] = await Promise.all([
      api.device.getTrash('folders'),
      api.device.getTrash('files'),
    ]);

    // Dispatch load
    dispatch(state.files.actions.loadTrash({
      id: 'trash',
      revision: 0,
      folderCount: folders ? folders.folderCount : 0,
      fileCount: files ? files.fileCount : 0,
      folders: folders ? folders.folders : [],
      files: folders ? files.files : [],
    }));

    // Load more
    if (!nochunks) {
      const moreFolders = folders && folders.moreChunks;
      const moreFiles = files && files.moreChunks;
      const moreItems = moreFolders || moreFiles;
      moreItems && crawlTrash(dispatch, 2, moreFolders, moreFiles);
      // Sync trash until we leave it
      syncTrash(dispatch, 0);
    }

  } catch(e) {
    fail('trash', e);
  }
}

export async function loadSearch(dispatch: Dispatch<AnyAction>, id: string, query: string) {
  try {
    const search = await api.folder.search(id, query, false);
    const fileKeys = search && search.results ? search.results.filter(e => isQuickkey(e.quickkey)) : [];
    const folderKeys = search && search.results ? search.results.filter(e => isFolderkey(e.folderkey)) : [];
    const [folders, files] = await Promise.all([
      folderKeys.length > 0 && api.folder.getInfo(folderKeys.map(e => e.folderkey).join()),
      fileKeys.length > 0 && api.file.getInfo(fileKeys.map(e => e.quickkey).join()),
    ]);
    const infoFolders = folders ? folders['folderInfos'] ? folders['folderInfos'] : [folders.folderInfo] : [];
    const infoFiles = files ? files.fileInfos ? files.fileInfos : [files.fileInfo] : [];
    if (search && search.results) {
      analytics.gtag('search', {results: search.results.length});
      dispatch(state.files.actions.loadMulti({folders: infoFolders, files: infoFiles, virtual: 'search'}));
    } else {
      dispatch(state.files.actions.loadMulti({items: [], virtual: 'search'}));
    }
  } catch(e) {
    dispatch(state.files.actions.loadMulti({items: [], virtual: 'search'}));
  }
}

export async function loadRecent(dispatch: Dispatch<AnyAction>) {
  try {
    const recent = await api.file.recentlyModified(100);
    const ids = recent && recent.quickkeys && recent.quickkeys.join();
    const info = ids && await api.file.getInfo(ids);
    const files = info ? info.fileInfos ? info.fileInfos : [info.fileInfo] : [];
    dispatch(state.files.actions.loadMulti({
      virtual: 'recent',
      items: files,
    }));
  } catch(e) {
    fail('recent', e);
  }
}

export async function loadFollowing(dispatch: Dispatch<AnyAction>) {
  try {
    const following = await api.device.post('get_foreign_resources.php') as any;
    dispatch(state.files.actions.loadMulti({
      virtual: 'following',
      items: [...following.folders, ...following.files],
    }));
  } catch(e) {
    fail('following', e);
  }
}

export async function syncFolder(
  dispatch: Dispatch<AnyAction>,
  id: string,
  startRevision: number,
  startFileCount: number,
  startFolderCount: number,
  sort?: FilesStore['sort'],
  filter?: FilesStore['filter'],
) {
  try {
    const apiFolder = await api.folder.getInfo(id);
    const {revision, fileCount, folderCount} = apiFolder.folderInfo;
    const {pathname, hash} = history.location;
    const current = getFolderKeyFromUrl(pathname, hash);
    if (current === id) {
      if ((revision && revision > startRevision)
        || (fileCount && fileCount !== startFileCount)
        || (folderCount && folderCount !== startFolderCount)) {
        loadFolder(dispatch, id, false, true, undefined, sort, filter);
      }
      clearTimeout(_syncFolderTimer);
      _syncFolderTimer = setTimeout(() =>
        syncFolder(dispatch, id, revision, fileCount, folderCount, sort, filter), 2500);
    }
  } catch(e) {
    const error = e?.body?.response?.error;
    if (error === 105 || error === 104)
      fetchSession(true);
  }
}

export async function syncTrash(dispatch: Dispatch<AnyAction>, startRevision: number) {
  try {
    const id = 'trash';
    const apiFolder = await api.device.getStatus();
    const revision = apiFolder ? apiFolder['deviceRevision'] : startRevision;
    const {pathname, hash} = history.location;
    const current = getFolderKeyFromUrl(pathname, hash);
    if (current === id) {
      if (revision && revision > startRevision) {
        loadTrash(dispatch, true);
      }
      clearTimeout(_syncFolderTimer);
      _syncFolderTimer = setTimeout(() =>
        syncTrash(dispatch, revision), 2500);
    }
  } catch(e) {}
}

export async function crawlFolder(
  dispatch: Dispatch<AnyAction>,
  id: string,
  page: number,
  moreFolders: boolean,
  moreFiles: boolean,
  orderDir?: 'asc' | 'desc',
  orderBy?: 'name' | 'size' | 'created' | 'downloads',
) {
  try {
    const size = 100;
    const [folders, files] = await Promise.all([
      moreFolders && api.folder.getContent(id, 'folders', page, size, 'no', orderDir, orderBy),
      moreFiles && api.folder.getContent(id, 'files', page, size, 'no', orderDir, orderBy),
    ]);
    const hasFolders = folders && folders.folderContent && !!folders.folderContent.folders.length;
    const hasFiles = files && files.folderContent && !!files.folderContent.files.length;
    if (hasFolders)
      dispatch(state.files.actions.chunk({id, page, size, type: 'folders', content: folders}));
    if (hasFiles)
      dispatch(state.files.actions.chunk({id, page, size, type: 'files', content: files}));
    const loadMoreFolders = hasFolders && folders.folderContent.moreChunks;
    const loadMoreFiles = hasFiles && files.folderContent.moreChunks;
    if (loadMoreFolders || loadMoreFiles) {
      const {pathname, hash} = history.location;
      const current = getFolderKeyFromUrl(pathname, hash);
      if (current === id) {
        return await crawlFolder(dispatch, id, page + 1, loadMoreFolders, loadMoreFiles);
      }
    }
    return true;
  } catch(e) {
    return false;
  }
}

export async function crawlTrash(dispatch: Dispatch<AnyAction>, page: number, moreFolders: boolean, moreFiles: boolean) {
  const id = 'trash';
  const size = 100;
  const [folders, files] = await Promise.all([
    moreFolders && api.device.getTrash('folders', page),
    moreFiles && api.device.getTrash('files', page),
  ]);
  const hasFolders = folders && folders.folders && !!folders.folders.length;
  const hasFiles = files && files.files && !!files.files.length;
  if (hasFolders) {
    const type = 'folders';
    const content = {folderContent: folders} as FolderContent;
    dispatch(state.files.actions.chunk({id, page, size, type, content}));
  }
  if (hasFiles) {
    const type = 'files';
    const content = {folderContent: files} as FolderContent;
    dispatch(state.files.actions.chunk({id, page, size, type, content}));
  }
  const loadMoreFolders = folders && folders.moreChunks;
  const loadMoreFiles = files && files.moreChunks;
  if (loadMoreFolders || loadMoreFiles) {
    if (!history.location) return;
    const {pathname, hash} = history.location;
    const current = getFolderKeyFromUrl(pathname, hash);
    if (current === id) {
      return await crawlTrash(dispatch, page + 1, loadMoreFolders, loadMoreFiles);
    }
  }
  return true;
}

// Download

export function downloadState(items: {[id: string]: FilesItem}) {
  const keys = Object.keys(items);
  const count = keys.length;
  let size = 0;
  let skipped = 0;
  let downloaded = 0;
  let finished = 0;
  let completed = true;
  keys.forEach(id => {
    const file = items[id];
    if (file) {
      const status = file.uploadStatus || '';
      const isComplete = status === 'Completed';
      const hasError = isNaN(parseInt(status))
        && status !== 'Preparing…'
        && status !== 'Packaging'
        && status !== 'Completed';
      if (hasError) {
        skipped++;
        return;
      }
      size += (file.size || 0);
      downloaded += isComplete ? file.size : (file.uploadProgress || 0);
      if (isComplete)
        finished++;
      if (!hasError && !isComplete)
        completed = false;
    }
  });
  return {
    size,
    count,
    skipped,
    downloaded,
    finished,
    completed,
  };
}

export function downloadSync(
  dispatch: Dispatch<AnyAction>,
  downloads: {
    bytes: number,
    total: number,
    state: string,
    name: string,
    id: string,
  }[],
) {
  const items: FilesItem[] = [];
  downloads && downloads.forEach(download => {
    let downloadProgress = 0;
    let downloadStatus = '';
    switch(download.state) {
      case 'starting file':
      case 'writing file':
        downloadProgress = download.bytes;
        downloadStatus = download.bytes ? `${((download.bytes / download.total) * 100).toFixed(2)}%` : '0%';
        break;
      case 'finalizing zip':
        downloadProgress = download.total;
        downloadStatus = 'Packaging';
      case 'complete':
        downloadProgress = download.total;
        downloadStatus = 'Completed';
        break;
      case 'skipped file':
        downloadStatus = 'Skipped file';
        break;
      default:
        downloadStatus = 'Preparing…';
        break
    }
    items.push({
      type: 'archive',
      id: download.id,
      name: download.name,
      size: download.total,
      uploadStatus: downloadStatus,
      uploadProgress: downloadProgress,
      created: '',
      hierarchy: [],
      private: true,
      revision: 0,
      state: 0,
      url: '',
    });
  });

  if (items.length > 0) {
    dispatch(state.files.actions.downloadSync({items}));
  }
}

// Upload

export function uploadState(items: {[id: string]: FilesItem}) {
  const keys = Object.keys(items);
  const count = keys.length;
  let size = 0;
  let hashed = 0;
  let aborted = 0;
  let skipped = 0;
  let uploaded = 0;
  let finished = 0;
  let verifying = 0;
  let completed = true;
  keys.forEach(id => {
    const file = items[id];
    if (file) {
      const status = file.uploadStatus || '';
      const isAborted = status === 'Skipped';
      const isPolling = status === 'Verifying…';
      const isComplete = status === 'Completed';
      const isTransferred = isComplete || isPolling;
      const hasError = isNaN(parseInt(status))
        && status !== 'Queued'
        && status !== 'Preparing…'
        && status !== 'Verifying…'
        && status !== 'Completed'
        && status !== 'Conflict';
      if (isAborted)
        aborted++;
      if (hasError) {
        skipped++;
        return;
      }
      size += (file.size || 0);
      hashed += isTransferred ? file.size : (file.uploadHashed || 0);
      uploaded += isTransferred ? file.size : (file.uploadProgress || 0);
      if (isComplete)
        finished++;
      if (isPolling)
        verifying++;
      if (!hasError && !isComplete)
        completed = false;
    }
  });
  return {
    size,
    count,
    aborted,
    skipped,
    uploaded,
    hashed,
    finished,
    verifying,
    completed,
  };
}

export function uploadSync(
  dispatch: Dispatch<AnyAction>,
  file: FileUpload,
  error?: string,
) {
  let uploadHashed = file.transfer.hashed || 0;
  let uploadProgress = file.transfer.uploaded || 0;
  const status = `${((uploadProgress / file.info.size) * 100).toFixed(2)}%`;
  let uploadStatus = status === '0.00%' ? 'Queued' : status;
  if (file.state === FileState.Completed)
    analytics.gtag('upload');
  switch (file.state) {
    case FileState.Queued:
      uploadStatus = 'Queued';
      break;
    case FileState.Hashing:
      uploadStatus = 'Preparing…';
      break;
    case FileState.Verifying:
      uploadStatus = 'Verifying…';
      break;
    case FileState.Completed:
      uploadStatus = 'Completed';
      break;
    case FileState.Conflict:
      uploadStatus = 'Conflict';
      break;
    case FileState.Duplicate:
      uploadStatus = 'Duplicate';
      break;
    case FileState.Aborted:
      uploadStatus = 'Skipped';
      break;
    case FileState.Failed:
      uploadStatus = error ? error.toString() : 'Upload error (-1)';
      break;
  }
  dispatch(state.files.actions.uploadSync({
    id: file.info.dest,
    items: [{
      name: file.info.name,
      type: file.info.type,
      size: file.info.size,
      uploadStatus,
      uploadHashed,
      uploadProgress,
      id: file.id,
      url: getShareLink(
        file.quickkey,
        file.info.type,
        file.info.name,
      ),
      hierarchy: [],
      private: true,
      revision: 0,
      state: 0,
      created: '',
    }],
  }));
}

export function uploadWebSync(
  dispatch: Dispatch<AnyAction>,
  destination: string,
  uploads: {
    url: string,
    eta: string,
    size: number,
    status: string,
    active: boolean,
    quickkey: string,
    filename: string,
    uploadkey: string,
    createdUtc: string,
    percentage: number,
    statusCode: number,
    errorStatus: number,
  }[],
) {
  const items: FilesItem[] = [];
  uploads && uploads.forEach(upload => {
    const size = upload.size || 0;
    let uploadProgress = 0;
    let uploadStatus = '';
    if (upload.errorStatus === 0) {
      switch(upload.statusCode) {
        case 1:
        case 2:
          uploadStatus = 'Preparing…';
          break;
        case 3:
        case 4:
          uploadProgress = size > 0 ? (size / 100) * upload.percentage : 0;
          uploadStatus = `${upload.percentage.toFixed(2)}%`;
          break;
        case 6:
          uploadProgress = size;
          uploadStatus = 'Verifying…';
          break;
        case 99:
          uploadProgress = size;
          uploadStatus = 'Completed';
          break;
      }
    } else {
      switch(upload.errorStatus) {
        case 9:
          uploadStatus = 'Failed (9)';
          break;
        case 10:
          uploadStatus = 'Failed (too big)';
          break;
        case 11:
          uploadStatus = 'Failed (too small)';
          break;
        case 14:
          uploadStatus = 'Failed (14)';
          break;
        case 15:
          uploadStatus = 'Failed (invalid url)';
          break;
        case 16:
          uploadStatus = 'Failed (missing file)';
          break;
        case 17:
          uploadStatus = 'Failed (image too big)';
          break;
        case 19:
          uploadStatus = 'Failed (virus found)';
          break;
        case 38:
          uploadStatus = 'Failed (partial file)';
          break;
        case 39:
          uploadStatus = 'Failed (storage full)';
          break;
      }
    }

    items.push({
      size,
      uploadStatus,
      uploadProgress,
      id: upload.quickkey,
      name: upload.filename,
      created: upload.createdUtc,
      hierarchy: [],
      private: true,
      revision: 0,
      state: 0,
      url: '',
      // TODO: get type from api, quick hack
      type: upload.filename.match(/\.(jpg|jpeg|png|gif|bmp|tiff)$/i)
        ? 'image'
        : upload.filename.match(/\.(aac|wav|ogg|mp3|flac|wma)$/i)
          ? 'music'
          : upload.filename.match(/\.(mp4|mkv|mv4|avi|mov|webm|flv|wmv)$/i)
            ? 'video'
            : 'file',
    });
  });

  if (items.length > 0) {
    dispatch(state.files.actions.uploadSync({items, id: destination}));
  }
}

export async function uploadAddWebFile(dispatch: Dispatch<AnyAction>, id: string, url: string, name: string) {
  try {
    analytics.gtag('webupload');
    const path = `${getHost()}/api/1.5/upload/add_web_upload.php`;
    const options = {excludeEndpointUrl: true};
    const params = {url, folderKey: id, filename: name};
    const response = await api.device.post(path, params, options);
    const uploadKey = response && response['uploadKey'];
    dispatch(state.files.actions.uploadWeb({id, files: [uploadKey]}));
    return await response;
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

export async function uploadGetWebFiles(uploadKey?: string) {
  try {
    const path = `${getHost()}/api/1.5/upload/get_web_uploads.php`;
    const options = {excludeEndpointUrl: true};
    const params = {uploadKey, allWebUploads: 'yes'};
    return await api.device.post(path, params, options);
  } catch(e) {
    return e && e.body ? e.body.response : e;
  }
}

// Utilities

export function isQuickkey(id: unknown) {
  const LENGTH_QUICKKEY = 15;
  const LENGTH_QUICKKEY_OLD = 11;
  if (!id || typeof id !== 'string') return false;
  if (id.length !== LENGTH_QUICKKEY && id.length !== LENGTH_QUICKKEY_OLD) return false;
  if (/[^a-z0-9]/.test(id)) return false;
  return true;
}

export function isFolderkey(id: unknown) {
  const LENGTH_FOLDERKEY = 13;
  const key = id && id.toString();
  if (!key) return false;
  if (key === 'myfiles'
    || key === 'trash'
    || key === 'recent'
    || key === 'following'
  ) return true;
  if (key.length !== LENGTH_FOLDERKEY) return false;
  if (/[^a-z0-9]/.test(key)) return false;
  return true;
}

export function getShareLink(id: string, type?: string, name?: string) {
  if (!id) return '';
  const slug = name && name.length ? '/' + name.split(' ').join('+') : '';
  const base = getHost();
  if (!type) type = isFolderkey(id) ? 'folder' : '';
  switch (type) {
    case 'folder':
      return `${base}/folder/${id}${slug}`;
    case 'image':
      return `${base}/view/${id}${slug}/file`;
    default:
      return `${base}/file/${id}${slug}/file`;
  };
}

export function getFolderKeyFromUrl(path: string, hash?: string) {
  for (const pattern of [
    /#\/([a-z0-9]+)/,         // hash
    /#([a-z0-9]+)/,           // legacy
    /([a-z0-9]+)/,            // normal
    /\/folder\/([a-z0-9]+)/,  // shared
    /\/file\/([a-z0-9]+)/,    // download
    /\/content\/([a-z0-9]+)/, // unicorn
    /\/files\/([a-z0-9]+)/,   // reserved
    /\/myfiles\/([a-z0-9]+)/, // reserved
  ]) {
    const pathMatch = path && path.match(pattern);
    const pathKey = pathMatch && pathMatch.pop();
    if (isFolderkey(pathKey)) return pathKey;
    const hashMatch = hash && hash.match(pattern);
    const hashKey = hashMatch && hashMatch.pop();
    if (isFolderkey(hashKey)) return hashKey;
  }
  return false;
}

export function parseContentItem(item: any, hierarchy?: any, chain?: any, cache?: any) {
  const id = item.quickkey || item.folderkey;
  const type = item.filetype || 'folder';
  const name = item.filename || item.name;
  const size =  item.totalSize && item.totalSize > item.size
    ? item.totalSize
    : (item.size || 0);
  const url = item.links
    ? (item.links.view || item.links.normalDownload)
    : getShareLink(id, type, name);

  let files = item.totalFiles > item.fileCount ? item.totalFiles : (item.fileCount || 0);
  let folders = item.totalFolders > item.folderCount ? item.totalFolders : (item.folderCount || 0);

  // Workaround for empty root folders that had previous items
  if (id === 'myfiles') {
    if (item.folderCount === 0 && item.totalFolders > 0)
      folders = 0;
    if (item.fileCount === 0 && item.totalFiles > 0)
      files = 0;
  }

  const parent = hierarchy ? hierarchy[hierarchy.length - 1] : undefined;
  const parentKey = parent ? parent[0] : undefined;
  const parentName = parent ? parent[1] : undefined;

  return {
    id,
    url,
    name,
    type,
    size,
    files,
    folders,
    state: 0,
    flag: item.flag || 0,
    hash: item.hash || '',
    downloads: item.downloads || 0,
    filedrop: item.dropboxEnabled || false,
    owner: item.ownerName,
    revision: item.revision,
    created: item.createdUtc,
    deleted: item.deleteDate,
    parentKey: item.parentFolderkey || parentKey,
    parentName: item.parentName || parentName,
    shared: item.dateSharedUtc,
    description: item.description,
    password: !!item.passwordProtected,
    private: item.privacy === 'private',
    hierarchy: hierarchy ? [...hierarchy, [id, name]]
      : chain ? [...chain.reverse().map((f: any) => [f.folderkey, f.name])]
      : cache ? cache.hierarchy : [],
  };
}

export function copyLink(link: string) {
  analytics.gtag('copy_link');
  Clipboard.setText(link);
}

export function getTimeStamp(time: string) {
  const invalid = '0000-00-00 00:00:00';
  if (!time) return invalid;
  try {
    return formatTimestamp(new Date(time.replace(/\s/g, 'T')), 'yyyy-MM-dd HH:mm:ss');
  } catch(e) {
    return invalid;
  }
}

export function getTimeSince(time: string) {
  const invalid = '0000-00-00 00:00:00';
  if (!time) return invalid;
  try {
    return formatDistance(new Date(time.replace(/\s/g, 'T')), Date.now());
  } catch(e) {
    return invalid;
  }
}

export function getDetails(type: string, details: {
  files?: number,
  folders?: number,
  downloads?: number,
  size?: number,
}) {
  if (type === 'folder') {
    const folderCount = details.folders >= 9999 ? t`many` : details.folders;
    const fileCount = details.files >= 9999 ? t`many` : details.files;
    const folders = `${folderCount} ${details.folders === 1 ? 'folder' : 'folders'}`;
    const files = `${fileCount} ${details.files === 1 ? 'file' : 'files'}`;
    return `${folders}, ${files}`;
  } else {
    const downloadCount = details.downloads >= 999999 ? t`many` : details.downloads;
    const downloads = `${downloadCount} download${details.downloads === 1 ? '' : 's'}`;
    const size = `${bytesize(details.size)}`;
    return `${downloads}, ${size}`;
  }
}

export function getPhoto(id: string, hash: string, flag: number, isPrivate: boolean) {
  if (!testBit(flag, FilesItemFlag.isViewable)) return '';
  const env = getEnvironment();
  const subdomain = env === 'www' ? `www${parseInt(hash.charAt(0), 16) + 1}` : env;
  const host = (HOSTS.LIVE).replace('www', subdomain);
  const authed = isPrivate && sessionToken ? `?session_token=${sessionToken}` : '';
  return `${host}/convkey/${hash.substr(0, 4)}/${id}3g.jpg${authed}`;
}

export function getKeysFromUrl(url: string) {
  const parts = url.split(getHost());
  const keys: string[] = [];
  let found: boolean;
  const append = (url: string) => {
    if (isFolderkey(url) || isQuickkey(url)) {
      keys.push(url);
      found = true;
    }
  };

  parts.forEach(url => {
    const segments = url.split('/');
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      const urls = segment.split(',');
      found = false;
      urls.forEach(append);
      if (found) break;
    }
  });

  return keys;
}

export function getIcon(type: string, name: string, primaryColor: string) {
  let icon = Vectors.fileGeneric;
  let fill = Light.fileIconColor;

  if (name && name.length && name.includes('pdf')) {
    return {icon: Vectors.filePDF, fill, type: 'pdf'};
  }

  switch (type) {
    case 'folder':
      fill = primaryColor;
      icon = Vectors.fileFolder;
      break;
    case 'text':
      icon = Vectors.fileText;
      break;
    case 'code':
      icon = Vectors.fileCode;
      break;
    case 'archive':
      icon = Vectors.fileArchive;
      break;
    case 'audio':
      icon = Vectors.fileAudio;
      break;
    case 'video':
      icon = Vectors.fileVideo;
      break;
    case 'image':
      icon = Vectors.fileImage;
      break;
    case 'spreadsheet':
      icon = Vectors.fileSpreadsheet;
      break;
    case 'pdf':
      icon = Vectors.filePDF;
      break;
    case 'presentation':
      icon = Vectors.filePresentation;
      break;
    case 'document':
      icon = Vectors.fileDocument;
      break;
  };

  return {icon, fill, type};
}

/* TODO: legacy function, needs refactoring */
export function appendFolderPathToFiles(items: any, original: any, callback: any) {
  // The goal of this function is to mimic the affects of uploading folders through the folder browse input
  // it converts webkitEntries to an Html5FileObject,
  // and adds a folderpath to match the functionality of using a folder browse input,
  // currently Chrome only (webkitGetAsEntry).
  let files = [];
  const entries = [];
  let counter = 0;
  let directoryFound = false;

  const toArray = (list: any) => Array.prototype.slice.call(list || [], 0);
  const errorHandler = () => {};
  const getDirectoryItems = (reader: any, callback: any) => {
    let entries = [];
    const readEntries = function() {
      counter++;
      reader.readEntries(function(results: any) {
        if (!results.length) {
          entries.sort();
          counter--;
          callback(entries);
        } else {
          entries = entries.concat(toArray(results));
          counter--;
          readEntries();
        }
      }, errorHandler);
    };
    readEntries();
  };

  const readDirectory = (entries: any) => {
    if (entries <= 0) callback(files);
    for (let i = 0; i < entries.length; i++) {
      if (entries[i]) {
        if (entries[i]['isDirectory']) {
          const reader = entries[i].createReader();
          getDirectoryItems(reader, readDirectory);
        } else {
          // Path to the current directory (first entry path in directory)
          let folderPath = entries[i].fullPath.slice(1).split('/'); // Remove starting slash.
          folderPath.pop(); // Remove filename.
          folderPath = folderPath.join('/');
          // Creates a file object from the entry.
          counter++;
          entries[i].file((file) => {
            counter--;
            try {
              // Attach the url so we know where its located.
              file.mfRelativePath = folderPath + '/' + file.name;
              // Add the file to the array.
              files = [...files, file];
              //console.log('added', file.name);
            } catch(e) {
              //console.log('failed', folderPath + '/' + file.name, e);
            }
            // Check if we are completely done.
            if (counter <= 0) callback(files);
          }, errorHandler);
        }
      }
    }
  };

  if (items) {
    for (let i = 0; i < items.length; i++) {
      if (!items[i].webkitGetAsEntry) break;
      entries[i] = items[i].webkitGetAsEntry();
      if (entries[i] && entries[i]['isDirectory'])
        directoryFound = true;
      }
  }

  if (directoryFound)
    readDirectory(entries);
  else
    callback(original);
}
