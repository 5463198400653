import HashWorker from 'worker-loader!./lib/hash/hash.worker';

const workers: {[id:string]: Worker} = {};

export function hashFile(
  file: File | string,
  progress: (bytes: number) => void,
  cancelId: string,
): Promise<string> {
  return new Promise((resolve, reject) => {
    const hasher = workers[cancelId] = new HashWorker();
    hasher.onmessage = (e) => {
      switch (e.data.type) {
        case 'mf::hash::completed':
          resolve(e.data.payload);
          hasher.terminate();
          break;
        case 'mf::hash::progress':
          progress(e.data.payload);
          break;
        case 'mf::hash::failure':
          reject(e.data.payload);
          hasher.terminate();
          delete workers[cancelId];
          break;
      }
    };
    hasher.onerror = (e) =>
      reject(e.message ? e : new Error('Hash worker failed'));
    hasher.postMessage({file});
  });
}

export function cancelHashing(id: string) {
  const hasher = workers[id];
  if (hasher) {
    hasher.terminate();
    delete workers[id];
  }
}
