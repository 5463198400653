import React from 'react';
import {i18n} from '@lingui/core';
import {Provider} from 'react-redux';
import {I18nProvider} from '@lingui/react';
import {App, UserInterface} from 'react-ult';
import {isDev, isLocal, isTouch} from 'features/platform';
import {AppFrame} from 'view/AppFrame';
import store from 'store';

App.initialize(isLocal() || isDev(), isLocal());
UserInterface.useCustomScrollbars(!isTouch());
UserInterface.setMainView(
  <Provider store={store}>
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      <AppFrame/>
    </I18nProvider>
  </Provider>
);
