import {createMemoryHistory, createBrowserHistory} from 'history';
import {App, Input, Modal, Popup} from 'react-ult';
import {getFolderKeyFromUrl} from 'features/mediafire';
import analytics from 'features/analytics';
import {isWeb} from 'features/platform';
import {AppUserGroup} from 'globals/types';
import {SCOPE} from 'globals/config';

export function reporter(_store: any) {
  return {
    initReporter: (
      _state: any,
      user: false | {ekey: string, email: string, group: AppUserGroup}) => {
      const userGroup = user ? user.group : AppUserGroup.Unregistered;
      const userId = user ? user.ekey : undefined;
      analytics.start('mfv2', userId, userGroup);
    },
    pluginReporter: (_store: any) => (next: any) => (action: any) => {
      analytics.log(action.type)
      try {
        return next(action);
      } catch(error) {
        analytics.notify(error);
      }
    },
  };
}

export function activate(store: any) {
  return {
    initActivate: (state: any) => {
      state.dispatch(store.app.actions.activate({active: true}));
      App.activationStateChangedEvent.subscribe(activated => {
        const payload = {active: activated === 1};
        state.dispatch(store.app.actions.activate(payload));
      });
    }
  };
}

export function navigate(store: any) {
  // Choose location engine
  const history = isWeb() ? createBrowserHistory() : createMemoryHistory();

  // Get current folder from location
  const {pathname, hash} = history.location;
  const current = getFolderKeyFromUrl(pathname, hash) || 'myfiles';

  // Init syncing location with redux store
  const initNavigate = (state: any, callback: Function) => {
    if (current) {
      state.dispatch(store.files.actions.navigate({
        id: current,
        syncing: false,
      }));
    }

    // Listen for history changes (browser back, fwd, etc)
    history.listen(e => {
      const {pathname, hash} = e['location'];
      const replace: boolean = e['action'] === 'REPLACE';
      const id = getFolderKeyFromUrl(pathname, hash);
      analytics.screen(pathname);
      if (id && !replace) {
        callback(id);
        state.dispatch(store.files.actions.navigate({
          id,
          syncing: true,
        }));
      }
    });

    // Listen for Android back button
    Input.backButtonEvent.subscribe(() => {
      if (Modal.isDisplayed()) {
        Modal.dismissAll();
      } else if (Popup.isDisplayed()) {
        Popup.dismissAll();
      } else {
        history.back();
      }
      return true;
    });
  };

  // Middleware to sync location with redux store
  const pluginNavigate = (state: any) => (next: any) => (action: any) => {
    if (action.type === 'app/feature' && action?.payload?.feature) {
      history.push({pathname: 'early-access'});
    } else if (action.type === 'files/navigate') {
      state.dispatch(store.app.actions.feature({feature: null}));
      const {id, syncing} = action.payload;
      if (id) {
        const pathname = `${SCOPE}${id}`;
        if (syncing) {
          history.replace({pathname, hash: '', search: ''});
        } else {
          history.push(pathname);
        }
      } else {
        history.back();
      }
    }
    return next(action);
  };

  // Return middleware and history engine
  return {initNavigate, pluginNavigate, history, current};
}
