import {Dispatch} from 'redux';
import {ViewOnLayoutEvent} from 'react-ult/dist/common/Types';
import {Types as TypesWebViewExt} from 'react-ult-ext-webview';
import {login, openPage, load, loadTrash} from 'features/mediafire';
import {getSecureCookies} from 'features/filesystem';
import analytics from 'features/analytics';
import {getHost} from 'features/platform';
import {AppFeatures} from 'globals/types';
import {state} from 'store';

// GENERAL

export function layout(dispatch: Dispatch) {
  return (e: ViewOnLayoutEvent) => {
    const {width, height} = e;
    dispatch(state.app.actions.resize({width, height}));
  }
}

export function menuOpen(dispatch: Dispatch) {
  return () => {
    dispatch(state.app.actions.menu({open: true}));
  }
}

export function menuClose(dispatch: Dispatch) {
  return () => {
    dispatch(state.app.actions.menu({open: false}));
  }
}

export function stateOn(setState: Function) {
  return () => {
    setState(true);
  }
}

export function stateOff(setState: Function) {
  return () => {
    setState(false);
  }
}

// PROMOS

export function earlyAccess(dispatch: Dispatch) {
  return (feature: AppFeatures, menu_position: number) => () => {
    analytics.record();
    analytics.amplitude('menu_promo_click', {product: feature, menu_position});
    dispatch(state.app.actions.feature({feature}));
  }
}

// AUTHENTICATION

export function loginNativeRedirect(setState: Function) {
  return async (e: TypesWebViewExt.WebViewNavigationState) => {
    const authenticated = e.url === 'https://www.mediafire.com/';
    if (authenticated) {
      setState(true);
    }
  }
}

export function loginNativeAuth(dispatch: Dispatch) {
  return async (e: TypesWebViewExt.WebViewMessageEvent) => {
    if (e.nativeEvent.data.includes('Cookie:')) {
      const cookies = await getSecureCookies();
      const authenticated = !!cookies.user;
      if (authenticated) {
        await login(dispatch, cookies);
        load(dispatch, 'myfiles', true);
        loadTrash(dispatch, true);
      }
    }
  }
}

// NAVIGATING

export function gotoLogin() {
  return async (e: any) => {
    e.stopPropagation && e.stopPropagation();
    openPage(`${getHost()}/login/`, true);
  }
}

export function gotoUpgrade(query?: string) {
  return async (e: any) => {
    e.stopPropagation && e.stopPropagation();
    openPage(`${getHost()}/upgrade/${query ? `?${query}` : ''}`);
  }
}

export function gotoAccount() {
  return async (e: any) => {
    e.stopPropagation && e.stopPropagation();
    openPage(`${getHost()}/myaccount/`);
  }
}

export function gotoAccountAvatar() {
  return async (e: any) => {
    e.stopPropagation && e.stopPropagation();
    openPage(`${getHost()}/myaccount/customize_avatar.php`);
  }
}

export function gotoSupport() {
  return async (e: any) => {
    e.stopPropagation && e.stopPropagation();
    openPage(`${getHost()}/help/submit_a_ticket.php`);
  }
}

export function gotoEarnSpace() {
  return async (e: any) => {
    e.stopPropagation && e.stopPropagation();
    openPage(`${getHost()}/earnspace/`);
  }
}
